export function validateNIP(nip) {
    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    let checksum = 0;
    for (var i = 0; i < 9; i++) {
        var c = nip[i];
        if (c >= '0' && c <= '9') {
            checksum += parseInt(c) * weights[i];
        } else {
            return false;
        }
    }
    if (checksum % 11 !== parseInt(nip[9])) return false;
    return true;
}

export const getNamePL = name => name?.split('|')[0];

export const getNameEN = name => name?.split('|')[1];

export const formatMealsCount = n => {
    if (n === 0) {
        return '0 dań';
    }
    if (n === 1) {
        return '1 danie';
    }
    if (n < 5) {
        return n + ' dania';
    }
    return n + ' dań';
};

export const truncateLabel = (text, maxLen) => {
    if (text.length > maxLen + 3) {
        return text.slice(0, maxLen) + '...';
    }
    return text;
};

export const readableFileSize = size => {
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

export const uploadFile = (url, formData, token, onError, onDone) => {
    fetch(process.env.REACT_APP_API_ADDR + url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: formData,
    })
        .then(async res => {
            const data = await res.json();
            if (res.ok) {
                onDone(data);
            } else {
                const msg =
                    res.status === 403
                        ? 'Wystąpił błąd uprawnień użytkownika. Prosimy spróbować ponownie. Jeżeli problem będzie się powtarzał, prosimy o ponowne zalogowanie do systemu.'
                        : data.error || 'Wystąpił błąd.';
                onError(msg);
            }
        })
        .catch(err => {
            onError(
                'Wystąpił błąd sieciowy przy wysyłaniu pliku. Prosimy upewnić się, że urządzenie jest w trybie online i spróbować ponownie.'
            );
        });
};

export const formatCategoriesCount = n => {
    if (n === 0) {
        return 'kategorii';
    }
    if (n === 1) {
        return 'kategoria';
    }
    if (n < 5) {
        return 'kategorie';
    }
    return 'kategorii';
};

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Typography } from '@material-ui/core';

import VerticallyCenteredSubLayout from '../layouts/VerticallyCenteredSubLayout';

const RegistrationFinishView = () => {
    return (
        <VerticallyCenteredSubLayout>
            <img
                style={{ width: '65%', margin: '0 auto', display: 'block' }}
                src="/foodinly_logo_crop.svg"
                alt="logo foodinly"
            ></img>
            <Box mb={3}>
                <Typography color="textPrimary" variant="h2" align="center">
                    Dziękujemy za dokonanie rejestracji!
                </Typography>
            </Box>
            <Box mt={3} mb={1}>
                <Typography align="left" color="textSecondary" variant="body1">
                    Na Twój adres e-mail przesłaliśmy link umożliwiający ustawienie hasła do
                    konta i rozpoczęcie pracy z aplikacją.
                </Typography>
            </Box>
            <Typography color="textSecondary" variant="body1">
                <Link component={RouterLink} to="/" variant="h6">
                    Wróć do ekranu logowania
                </Link>
            </Typography>
        </VerticallyCenteredSubLayout>
    );
};

export default RegistrationFinishView;

import React, { useState } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
    Box,
    Button,
    Link,
    TextField,
    Typography,
    CircularProgress,
    //makeStyles
} from '@material-ui/core';

import { Alert } from '@material-ui/lab';
import VerticallyCenteredSubLayout from '../layouts/VerticallyCenteredSubLayout';

const PasswordResetFinalizeView = () => {
    const [state, setState] = useState({
        submitting: false,
        error: null,
        success: null,
    });

    const { register, errors, handleSubmit } = useForm();

    const { token } = useParams();
    const location = useLocation();
    const newAccount = location.search.includes('f');

    const apiResetFinalizeCall = async (token, password) => {
        setState({ ...state, submitting: true });
        fetch(process.env.REACT_APP_API_ADDR + 'auth/confirmreset', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token, password }),
        })
            .then(async response => {
                if (response.status === 200) {
                    setState({
                        ...state,
                        error: null,
                        submitting: false,
                        success: true,
                    });
                } else {
                    setState({
                        ...state,
                        submitting: false,
                        error:
                            'Link do resetu hasła jest nieaktywny. Możliwe, że został już wcześniej wykorzystany.',
                        success: false,
                    });
                }
            })
            .catch(error => {
                setState({
                    ...state,
                    submitting: false,
                    error:
                        'Wystąpił błąd sieciowy. Prosimy upewnić się że urządzenie jest w trybie online i spróbowac ponownie.',
                    success: false,
                });
            });
    };

    return (
        <VerticallyCenteredSubLayout>
            <img
                style={{ width: '65%', margin: '0 auto', display: 'block' }}
                src="/foodinly_logo_crop.svg"
                alt="logo foodinly"
            ></img>
            <form
                onSubmit={handleSubmit(data => {
                    apiResetFinalizeCall(token, data.password);
                })}
            >
                <Box mb={3}>
                    <Typography color="textPrimary" variant="h2" align="center">
                        {newAccount ? 'Ustaw hasło do konta' : 'Odzyskiwanie hasła do konta'}
                    </Typography>
                </Box>
                <Box mt={3} mb={1}>
                    <Typography align="center" color="textSecondary" variant="body1">
                        {newAccount
                            ? 'Przed tobą ostatni krok rejestracji. Ustaw bezpieczne hasło do swojego konta, a następnie zaloguj się do panelu.'
                            : 'Hasło do Twojego konta zostanie zmienione.'}
                    </Typography>
                    {state.error && (
                        <Box mt={3} mb={1}>
                            <Alert severity="error">{state.error}</Alert>
                        </Box>
                    )}
                    {!!state.success && (
                        <Box mt={3} mb={3}>
                            <Alert severity="success">
                                Zmiana hasła powiodła się. Aby zalogować się w aplikacji,
                                przejdź do{' '}
                                <Link component={RouterLink} to="/" variant="h6">
                                    ekranu logowania
                                </Link>
                                .
                            </Alert>
                        </Box>
                    )}
                </Box>
                <TextField
                    fullWidth
                    label="Nowe hasło"
                    margin="normal"
                    name="password"
                    type="password"
                    variant="outlined"
                    inputRef={register({
                        required: true,
                        minLength: 8,
                        maxLength: 100,
                        pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
                    })}
                    error={!!errors.password}
                    helperText={
                        !!errors.password
                            ? 'Hasło musi posiadać przynajmniej 8 znaków, oraz przynajmniej jedną małą literę, dużą literę oraz cyfrę.'
                            : null
                    }
                />
                <Box my={2}>
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        disabled={state.submitting}
                    >
                        {newAccount ? 'Ustaw hasło' : 'Zmień hasło'}{' '}
                        {state.submitting && <CircularProgress size={16}></CircularProgress>}
                    </Button>
                </Box>
                {!newAccount && (
                    <Typography color="textSecondary" variant="body1">
                        Nie chcesz resetować hasła?{' '}
                        <Link component={RouterLink} to="/" variant="h6">
                            Wróć do ekranu logowania
                        </Link>
                    </Typography>
                )}
            </form>
        </VerticallyCenteredSubLayout>
    );
};

export default PasswordResetFinalizeView;

import React from 'react';
import DashboardPageContainer from '../layouts/partials/DashboardPageContainer';
import {
    Typography,
    Grid,
    Box,
    makeStyles,
    DialogTitle,
    Dialog,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    Chip,
    Divider,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import useAuthApiGet from '../hooks/useAuthApiGet';
import { Edit, ArrowBack } from '@material-ui/icons';
import DashboardError from '../components/DashboardError';
import { getNameEN, getNamePL } from '../utils';

const useStyles = makeStyles(theme => ({
    gridFix: {
        margin: 0,
        width: '100%',
    },
    bottomSkeleton: {
        marginLeft: '12px',
    },
    bgPrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    modalRoot: {
        [theme.breakpoints.up('md')]: {
            minWidth: 520,
        },
    },
}));

const MenuView = () => {
    const classes = useStyles();
    const history = useHistory();

    const { error: loadingError, data } = useAuthApiGet('panel/menu');

    if (!!loadingError) {
        return <DashboardError error={{ msg: loadingError }} />;
    }

    return (
        <DashboardPageContainer>
            <Box ml={2} mb={2} mt={2}>
                <Typography variant="h1">Dania w menu</Typography>
            </Box>
            <Grid className={classes.gridFix} container spacing={3}>
                <Grid item xs={12}>
                    <Skeleton height={120} />
                    <Skeleton height={120} />
                    <Skeleton height={120} />
                    <Skeleton height={120} />
                    <Box display="flex">
                        <Skeleton variant="circle" height={50} width={50} />
                        <Skeleton height={50} width="20%" className={classes.bottomSkeleton} />
                    </Box>
                </Grid>
            </Grid>

            <Dialog
                classes={{
                    paper: classes.modalRoot,
                }}
                aria-labelledby="simple-dialog-title"
                open={!!data && !loadingError}
            >
                <DialogTitle id="simple-dialog-title">Wybierz kateogrię menu</DialogTitle>
                <List>
                    {!!data &&
                        data.map(cat => (
                            <ListItem
                                button
                                component={RouterLink}
                                to={`/meals/${cat._id}`}
                                key={cat._id}
                            >
                                <ListItemText
                                    primary={getNamePL(cat.category)}
                                    secondary={getNameEN(cat.category)}
                                />
                                <ListItemSecondaryAction>
                                    <Chip label={cat.meals.length} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    {!!data && data.length === 0 && (
                        <Box mt={2} mb={2}>
                            <Typography align="center" variant="body2">
                                Nie posiadasz jeszcze żadnych kategorii menu.
                            </Typography>
                        </Box>
                    )}
                    <Divider />
                    <ListItem button component={RouterLink} to="/menu">
                        <ListItemAvatar>
                            <Avatar className={classes.bgPrimary}>
                                <Edit />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Edytuj kategorie"
                            secondary="Zarządzanie kategoriami menu"
                        />
                    </ListItem>
                    <ListItem button>
                        <ListItemAvatar>
                            <Avatar className={classes.bgPrimary}>
                                <ArrowBack />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Anuluj"
                            secondary="Wróć do poprzedniego ekranu"
                            onClick={() => {
                                history.goBack();
                            }}
                        />
                    </ListItem>
                </List>
            </Dialog>
        </DashboardPageContainer>
    );
};

export default MenuView;

import React from 'react';
import { Button, ListItemSecondaryAction, IconButton, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/Description';
import DashboardBottomCard from './DashboardBottomCard';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Skeleton } from '@material-ui/lab';
import dayjs from 'dayjs';

const LastOrdersCard = ({ orders }) => {
    return (
        <DashboardBottomCard
            icon={<DescriptionIcon />}
            title="Ostatnie faktury"
            button={
                <Button
                    endIcon={<ShoppingCartIcon />}
                    color="primary"
                    variant="contained"
                    size="small"
                    component={Link}
                    to="/payments"
                >
                    Wszystkie zamówienia
                </Button>
            }
        >
            <List>
                {!orders && (
                    <>
                        <Skeleton height={70}></Skeleton>
                        <Skeleton height={70}></Skeleton>
                        <Skeleton height={70}></Skeleton>
                        <Skeleton height={70}></Skeleton>
                    </>
                )}
                {!!orders &&
                    orders.length > 0 &&
                    orders.map(invoice => (
                        <ListItem key={invoice.identifier}>
                            <ListItemText
                                primary={`Faktura ${invoice.invoiceId}`}
                                secondary={`${dayjs(invoice.paid).format('DD-MM-YYYY')} | ${(
                                    invoice.totalPrice / 100
                                )
                                    .toFixed(2)
                                    .replace('.', ',')} PLN`}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    download
                                    href={process.env.REACT_APP_API_ADDR + invoice.downloadURL}
                                    edge="end"
                                    aria-label="pobierz"
                                >
                                    <GetAppIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}

                {!!orders && orders.length === 0 && (
                    <Typography variant="body2">
                        Twoje konto nie posiada jeszcze żadnych wystawionych faktur.
                    </Typography>
                )}
            </List>
        </DashboardBottomCard>
    );
};

export default LastOrdersCard;

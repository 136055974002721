import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
import { Add, Delete, Help, Warning } from '@material-ui/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UserContext } from '../UserContext';
import { getNameEN, getNamePL, uploadFile } from '../utils';
import { LightTooltip } from '../views/NewPaymentView';
import { validateName } from './CategoryEditor';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            style={{ display: value === index ? 'block' : 'none' }}
            {...other}
        >
            {children}
        </div>
    );
}

export const normalizePrice = value => {
    if (value === '') {
        return '';
    }
    let normalized = value.replace(/\D/g, '').replace(/\b0+/g, '');
    const len = normalized.length;
    if (len === 0) {
        return '0.00';
    }
    if (len === 1) {
        return '0.0' + normalized;
    }
    if (len === 2) {
        return '0.' + normalized;
    }
    return normalized.slice(0, len - 2) + '.' + normalized.slice(len - 2, len);
};

const numberMask = value => {
    return value.replace(/\D/g, '');
};

const useStyles = makeStyles(theme => ({
    inputRight: {
        textAlign: 'right',
    },
    labelContainer: {
        padding: 0,
    },
    imgBox: {
        aspectRatio: '3/2',
        width: 'calc(100% - 200px)',
        margin: '0 auto 12px auto',
        background: '#ddd',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        boxShadow: theme.shadows[1],
    },
}));

const calculateCalories = (protein, carbs, fat, setValue) => {
    const p = parseInt(protein || '0');
    const c = parseInt(carbs || '0');
    const f = parseInt(fat || '0');
    const calories = p * 4 + c * 4 + f * 9;
    if (calories === 0 || isNaN(calories)) {
        setValue('calories', '');
    } else {
        setValue('calories', calories);
    }
};

export const MealDialog = ({
    edited,
    meal,
    setEdited,
    dispatchAction,
    forCreation,
    categoryName,
}) => {
    const classes = useStyles();
    const {
        handleSubmit: handleEditSubmit,
        register: registerEdit,
        errors: errorsEdit,
        reset: resetEdit,
        getValues: getValuesEdit,
        setValue: setValueEdit,
    } = useForm();
    const [img, setImg] = useState('');
    useEffect(() => {
        if (!!edited && !!meal && !forCreation) {
            setImg(meal.image);
            resetEdit({
                namepl: getNamePL(meal.name),
                nameen: getNameEN(meal.name),
                price: meal.price,
                protein: meal.protein,
                carbs: meal.carbs,
                fat: meal.fat,
                calories: meal.calories,
                descPL: meal.descPL,
                descEN: meal.descEN,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edited, meal, forCreation]);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Dialog
            open={!!edited}
            onClose={() => {
                setEdited(null);
            }}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth
        >
            <form
                onSubmit={handleEditSubmit(meal => {
                    dispatchAction({
                        type: forCreation ? 'ADD' : 'EDIT_NAME',
                        payload: {
                            _id: edited,
                            name: meal.namepl + '|' + meal.nameen,
                            price: meal.price || undefined,
                            protein: meal.protein || undefined,
                            carbs: meal.carbs || undefined,
                            fat: meal.fat || undefined,
                            calories: meal.calories || undefined,
                            descPL: meal.descPL || undefined,
                            descEN: meal.descEN || undefined,
                            image: img,
                        },
                    });
                    setEdited(null);
                    setValue(0);
                })}
            >
                <DialogTitle id="form-dialog-title">
                    {forCreation ? 'Tworzenie dania' : 'Edycja dania'}
                </DialogTitle>{' '}
                <DialogContent style={{ minHeight: '404px' }}>
                    <Box sx={{ borderBottom: 1 }} mb={3}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            indicatorColor="primary"
                            variant="standard"
                        >
                            <Tab
                                classes={{ labelContainer: classes.labelContainer }}
                                style={{ minWidth: '25%' }}
                                label="Nazwa"
                            />
                            <Tab
                                classes={{ labelContainer: classes.labelContainer }}
                                style={{ minWidth: '25%' }}
                                label="Opis"
                            />
                            <Tab
                                classes={{ labelContainer: classes.labelContainer }}
                                style={{ minWidth: '25%' }}
                                label="Kalorie"
                            />
                            <Tab
                                classes={{ labelContainer: classes.labelContainer }}
                                style={{ minWidth: '25%' }}
                                label="Zdjęcie"
                            />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <DialogContentText>
                            {forCreation && (
                                <div style={{ marginBottom: '12px' }}>
                                    Wprowadź nazwy (polskojęzyczną oraz anglojęzyczną) dania,
                                    które chcesz dodać do kategorii: {getNamePL(categoryName)}.
                                    Wypełnienienie pozostałych pól jest opcjonalne. Po
                                    utworzeniu dania, pamiętaj o zapisaniu zmian.
                                </div>
                            )}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            id="namepl"
                            name="namepl"
                            label="Nazwa polskojęzyczna*"
                            type="text"
                            fullWidth
                            variant="outlined"
                            error={!!errorsEdit?.namepl}
                            inputRef={registerEdit({
                                required: true,
                                validate: validateName,
                            })}
                            lang="pl"
                        />
                        <Box height={16} />
                        <TextField
                            id="nameen"
                            name="nameen"
                            label="Nazwa anglojęzyczna*"
                            type="text"
                            error={!!errorsEdit?.nameen}
                            fullWidth
                            variant="outlined"
                            inputRef={registerEdit({
                                required: true,
                                validate: validateName,
                            })}
                            lang="en"
                        />
                        <Box height={16} />
                        <Grid container>
                            <Grid item lg={8} md={8} sm={6} />
                            <Grid item sm={6} md={4} lg={4}>
                                <TextField
                                    id="price"
                                    name="price"
                                    label="Cena"
                                    type="tel"
                                    inputMode="numeric"
                                    error={!!errorsEdit?.price}
                                    fullWidth
                                    maxWidth="50%"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                PLN
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={event => {
                                        const { value } = event.target;
                                        event.target.value = normalizePrice(value);
                                    }}
                                    inputRef={registerEdit({})}
                                    inputProps={{ className: classes.inputRight }}
                                    placeholder="0.00"
                                />
                            </Grid>
                        </Grid>
                        <Box height={16} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TextField
                            id="descPL"
                            name="descPL"
                            label="Opis polskojęzyczny"
                            error={!!errorsEdit?.descPL}
                            fullWidth
                            variant="outlined"
                            inputRef={registerEdit()}
                            multiline
                            rows={5}
                            lang="pl"
                        />
                        <Box height={16} />
                        <TextField
                            id="descEN"
                            name="descEN"
                            label="Opis anglojęzyczny"
                            error={!!errorsEdit?.descEN}
                            fullWidth
                            variant="outlined"
                            inputRef={registerEdit()}
                            multiline
                            rows={5}
                            lang="en"
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    id="protein"
                                    name="protein"
                                    label="Białko"
                                    type="tel"
                                    inputMode="numeric"
                                    error={!!errorsEdit?.protein}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">g</InputAdornment>
                                        ),
                                    }}
                                    onChange={event => {
                                        let { value } = event.target;
                                        value = numberMask(value);
                                        calculateCalories(
                                            getValuesEdit('protein'),
                                            getValuesEdit('carbs'),
                                            value,
                                            setValueEdit
                                        );
                                        event.target.value = value;
                                    }}
                                    inputRef={registerEdit({})}
                                    inputProps={{ className: classes.inputRight }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    id="carbs"
                                    name="carbs"
                                    label="Węglowodany"
                                    type="tel"
                                    inputMode="numeric"
                                    error={!!errorsEdit?.carbs}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">g</InputAdornment>
                                        ),
                                    }}
                                    onChange={event => {
                                        let { value } = event.target;
                                        value = numberMask(value);
                                        calculateCalories(
                                            getValuesEdit('protein'),
                                            value,
                                            getValuesEdit('fat'),
                                            setValueEdit
                                        );
                                        event.target.value = value;
                                    }}
                                    inputRef={registerEdit({})}
                                    inputProps={{ className: classes.inputRight }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    id="fat"
                                    name="fat"
                                    label="Tłuszcze"
                                    type="tel"
                                    inputMode="numeric"
                                    error={!!errorsEdit?.fat}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">g</InputAdornment>
                                        ),
                                    }}
                                    onChange={event => {
                                        let { value } = event.target;
                                        value = numberMask(value);
                                        calculateCalories(
                                            getValuesEdit('protein'),
                                            getValuesEdit('carbs'),
                                            value,
                                            setValueEdit
                                        );
                                        event.target.value = value;
                                    }}
                                    inputRef={registerEdit({})}
                                    inputProps={{ className: classes.inputRight }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Box height={16} />
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item sm={12} md={4}>
                                <TextField
                                    id="calories"
                                    name="calories"
                                    label="Kalorie"
                                    type="tel"
                                    inputMode="numeric"
                                    error={!!errorsEdit?.calories}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                kcal
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={event => {
                                        let { value } = event.target;
                                        value = numberMask(value);
                                        if (value !== '') {
                                            setValueEdit('protein', '');
                                            setValueEdit('carbs', '');
                                            setValueEdit('fat', '');
                                        }
                                    }}
                                    inputRef={registerEdit({})}
                                    inputProps={{ className: classes.inputRight }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <PhotoUpload
                            img={img}
                            fileUploaded={key => {
                                setImg(key);
                            }}
                        />
                    </TabPanel>
                    <Box height={16} />
                </DialogContent>{' '}
                <DialogActions>
                    <Button
                        onClick={() => {
                            setEdited(null);
                        }}
                    >
                        Anuluj
                    </Button>
                    <Button type="submit" color="primary">
                        Zatwierdź
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const PhotoUpload = ({ img, fileUploaded }) => {
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const uploadInput = useRef();
    const { user } = useContext(UserContext);
    const classes = useStyles();

    const hasPhoto = !!img;

    return (
        <>
            {!hasPhoto && (
                <>
                    <div
                        style={{
                            aspectRatio: '3/2',
                            width: 'calc(100% - 200px)',
                            margin: '0 auto 12px auto',
                            background: '#ddd',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '20px',
                        }}
                    >
                        {!uploading && (
                            <img
                                width="80px"
                                src="/foodinly_logo_404_web.svg"
                                alt="dodaj zdjęcie"
                                style={{ filter: 'brightness(0) invert(1)' }}
                            />
                        )}
                        {uploading && <CircularProgress size="50px" color="primary" />}
                    </div>

                    <Box textAlign="center">
                        <input
                            accept="image/jpeg"
                            style={{ display: 'none' }}
                            id="file-upload"
                            multiple
                            type="file"
                            onChange={e => {
                                const file = e.target.files[0];
                                const fd = new FormData();
                                fd.append('image', file, file.name);
                                setUploading(true);
                                uploadFile(
                                    'panel/menu/image',
                                    fd,
                                    user.token,
                                    err => {
                                        setError(err);
                                        setUploading(false);
                                    },
                                    data => {
                                        fileUploaded(data.key);
                                        setUploading(false);
                                    }
                                );
                            }}
                            ref={uploadInput}
                        />
                        <label htmlFor="file-upload">
                            <Button
                                disabled={uploading}
                                variant="contained"
                                color="primary"
                                endIcon={<Add />}
                                onClick={() => {
                                    uploadInput.current.click();
                                }}
                            >
                                Dodaj zdjęcie
                            </Button>
                        </label>
                        <IconButton edge="end" aria-label="delete">
                            <LightTooltip
                                placement="top"
                                arrow
                                title="Przesłane zdjęcia powinny posiadać orientację poziomą. Po przesłaniu, zdjęcie dania jest automatycznie docinane do proporcji 3:2."
                            >
                                <Help />
                            </LightTooltip>
                        </IconButton>
                        {error !== '' && (
                            <Typography mt={2} color="primary" variant="body2">
                                <Warning style={{ verticalAlign: '-20%' }} /> {error} Spróbuj
                                ponownie.
                            </Typography>
                        )}
                    </Box>
                </>
            )}
            {hasPhoto && (
                <>
                    <div className={classes.imgBox}>
                        <img
                            width="100%"
                            src={`${process.env.REACT_APP_S3_ADDR}/${img}`}
                            alt="zdjęcie dania"
                            style={{ borderRadius: '20px' }}
                        />
                    </div>
                    <Box textAlign="center" pt={1}>
                        <Button
                            variant="outlined"
                            endIcon={<Delete />}
                            onClick={() => {
                                fileUploaded('');
                            }}
                        >
                            Usuń zdjęcie
                        </Button>
                    </Box>
                </>
            )}
        </>
    );
};

import React, { useState, useContext } from 'react';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DashboardBottomCard from './DashboardBottomCard';
import { TextField, Grid, makeStyles, Box, Button, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { validateNIP } from '../utils';
import { UserContext } from '../UserContext';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles(theme => ({
    postCode: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: '49%',
        },
    },
    saved: {
        paddingLeft: '12px',
    },
    savedIcon: {
        paddingLeft: 6,
        paddingBottom: 6,
    },
}));

const getNIPError = billingNIP => {
    if (!billingNIP) {
        return null;
    }
    switch (billingNIP.type) {
        case 'minLength':
            return 'Niepopranwa długość numeru NIP';
        case 'maxLength':
            return 'Niepoprawna długość numeru NIP';
        case 'required':
            return 'Podanie numeru NIP jest wymagane';
        case 'validate':
            return 'Niepoprawna suma kontrolna numeru NIP';
        default:
            return billingNIP.type;
    }
};

const getPostCodeError = postCode => {
    if (!postCode) {
        return null;
    }
    switch (postCode.type) {
        case 'required':
            return 'Pole wymagane';
        case 'pattern':
            return 'Wymagany format kodu: 12-345';
        default:
            return JSON.stringify(postCode.type);
    }
};

const SettingsCard = ({ data, onUpdate }) => {
    const classes = useStyles();
    const { register, reset, handleSubmit, errors } = useForm();
    const [state, setState] = useState();
    const [form, setForm] = useState({ loading: false, error: null, done: false });
    const { user } = useContext(UserContext);

    const updateSettings = async data => {
        setForm({ ...form, loading: true, error: null, done: false });
        fetch(process.env.REACT_APP_API_ADDR + 'panel/me', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`,
            },
            body: JSON.stringify(data),
        })
            .then(async response => {
                if (!response.ok) {
                    const resData = await response.json();
                    const error = resData.error;
                    setForm({
                        ...form,
                        submitting: false,
                        error:
                            error ||
                            'Wystąpił błąd przy obsłudze zapytania. Prosimy spróbować ponownie.',
                        done: true,
                    });
                } else {
                    setForm({
                        ...form,
                        loading: false,
                        error: null,
                        done: true,
                    });
                    onUpdate(data);
                }
            })
            .catch(error => {
                setForm({
                    loading: false,
                    error:
                        'Wystąpił błąd sieciowy. Prosimy upewnić się że urządzenie jest w trybie online i spróbować ponownie.',
                });
            });
    };

    if (!state) {
        setState(data);
        reset({ ...data });
    }
    return (
        <DashboardBottomCard title="Dane rozliczeniowe" icon={<AccountBalanceIcon />}>
            <form
                onSubmit={handleSubmit(data => {
                    updateSettings(data);
                })}
            >
                <TextField
                    fullWidth
                    label="Nazwa firmy"
                    margin="normal"
                    name="billingCompany"
                    variant="outlined"
                    inputRef={register({
                        required: true,
                        minLength: 3,
                        maxLength: 200,
                    })}
                    error={!!errors.billingCompany}
                    helperText={
                        errors.billingCompany ? 'Podanie nazwy firmy jest wymagane' : null
                    }
                />
                <TextField
                    fullWidth
                    label="Adres"
                    margin="normal"
                    name="billingAddress"
                    variant="outlined"
                    inputRef={register({
                        required: { value: true, message: 'Podanie adresu jest wymagane.' },
                        minLength: 3,
                        maxLength: 200,
                    })}
                    error={!!errors.billingAddress}
                    helperText={errors.billingAddress ? 'Podanie adresu jest wymagane' : null}
                />
                <TextField
                    fullWidth
                    label="Numer NIP"
                    margin="normal"
                    name="billingNIP"
                    variant="outlined"
                    inputRef={register({
                        required: {
                            value: true,
                            message: 'Podanie numeru NIP jest wymagane.',
                        },
                        minLength: 10,
                        maxLength: 10,
                        validate: value => validateNIP(value),
                    })}
                    error={!!errors.billingNIP}
                    helperText={getNIPError(errors.billingNIP)}
                />
                <Grid container justify="space-between">
                    <Grid item xs={12} sm={6} className={classes.postCode}>
                        <TextField
                            fullWidth
                            label="Kod pocztowy"
                            margin="normal"
                            name="billingPostCode"
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                pattern: /^[0-9]{2}-[0-9]{3}$/,
                            })}
                            error={!!errors.billingPostCode}
                            helperText={getPostCodeError(errors.billingPostCode)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Miasto"
                            margin="normal"
                            name="billingCity"
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                minLength: 3,
                                maxLength: 200,
                            })}
                            error={!!errors.billingCity}
                            helperText={!errors.billingCity ? null : 'Pole wymagane'}
                        />
                    </Grid>
                </Grid>
                {form.done && form.error && <Alert severity="error">{form.error}</Alert>}
                <Box my={2} display="flex" alignItems="center">
                    <Button
                        color="primary"
                        size="medium"
                        type="submit"
                        variant="contained"
                        endIcon={<SaveIcon />}
                        disabled={form.loading}
                    >
                        Zapisz
                    </Button>
                    {!form.error && form.done && (
                        <>
                            <Typography variant="body2" className={classes.saved}>
                                Zapisano zmiany
                            </Typography>
                            <CheckIcon color="primary" className={classes.savedIcon} />
                        </>
                    )}
                </Box>
            </form>
        </DashboardBottomCard>
    );
};

export default SettingsCard;

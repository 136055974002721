import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';

export const NewPaymentDetailsList = ({ netPrice, totalPrice, monthsLabel, endDate }) => (
    <List>
        <ListItem>
            <ListItemText primary={monthsLabel} secondary="Okres odnowienia usługi" />
        </ListItem>
        <ListItem>
            <ListItemText primary={endDate} secondary="Nowy termin ważności usługi" />
        </ListItem>
        <ListItem>
            <ListItemText
                primary={
                    (netPrice / 100).toLocaleString('pl-PL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }) + ' PLN'
                }
                secondary="Cena netto"
            />
        </ListItem>
        <ListItem>
            <ListItemText
                primary={
                    (totalPrice / 100).toLocaleString('pl-PL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }) + ' PLN'
                }
                secondary="Cena brutto"
            />
        </ListItem>
    </List>
);

import { nanoid } from 'nanoid';

const getDiff = (oldAllergens, newAllergens, removed, added) => {
    console.log('new');
    console.log(newAllergens);
    console.log('old');
    console.log(oldAllergens);
    if (!removed) {
        removed = new Set();
    }
    if (!added) {
        added = new Set();
    }
    console.log(removed);
    console.log(added);

    let oldAllergensSet = new Set(oldAllergens);
    let newAllergensSet = new Set(newAllergens);
    [...oldAllergensSet]
        .filter(x => !newAllergensSet.has(x))
        .forEach(x => {
            if (added.has(x)) {
                added.delete(x);
            } else {
                removed.add(x);
            }
        });
    [...newAllergensSet]
        .filter(x => !oldAllergensSet.has(x))
        .forEach(x => {
            if (removed.has(x)) {
                removed.delete(x);
            } else {
                added.add(x);
            }
        });
    return {
        removed,
        added,
    };
};

const MealReducer = (data, action) => {
    switch (action.type) {
        case 'REMOVE':
            return data.filter(el => el._id !== action.payload);
        case 'ADD':
            return [
                ...data,
                {
                    _id: nanoid(),
                    name: action.payload.name,
                    price: action.payload.price,
                    protein: action.payload.protein,
                    carbs: action.payload.carbs,
                    fat: action.payload.fat,
                    calories: action.payload.calories,
                    descPL: action.payload.descPL,
                    descEN: action.payload.descEN,
                    image: action.payload.image,
                    allergens: [],
                },
            ];
        case 'MOVE_UP':
            let pos = data.findIndex(el => el._id === action.payload);
            if (pos === 0) return data;
            const tmp = data[pos - 1];
            data[pos - 1] = data[pos];
            data[pos] = tmp;
            return data;
        case 'MOVE_DOWN':
            let pos2 = data.findIndex(el => el._id === action.payload);
            if (pos2 === data.length - 1) return data;
            const tmp2 = data[pos2 + 1];
            data[pos2 + 1] = data[pos2];
            data[pos2] = tmp2;
            return data;
        case 'EDIT_NAME':
            console.log(action);
            let id = action.payload._id;
            let newName = action.payload.name;
            let newPrice = action.payload.price;
            let { protein, carbs, fat, calories, descPL, descEN, image } = action.payload;
            return data.map(cat =>
                cat._id === id
                    ? {
                          ...cat,
                          name: newName,
                          price: newPrice,
                          protein,
                          carbs,
                          fat,
                          calories,
                          descPL,
                          descEN,
                          image,
                      }
                    : cat
            );
        case 'EDIT_ALLERGENS':
            let id2 = action.payload._id;
            const allergens = action.payload.allergens;
            return data.map(cat =>
                cat._id === id2
                    ? {
                          ...cat,
                          ...getDiff(cat.allergens, allergens, cat.removed, cat.added),
                          allergens: allergens,
                      }
                    : cat
            );
        default:
            return data;
    }
};

export default MealReducer;

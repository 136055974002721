import React from 'react';
import useSWR from 'swr';
import DashboardPageContainer from '../layouts/partials/DashboardPageContainer';
import { Typography, Grid, Box, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import PaymentsTable from '../components/PaymentsTable';
import DashboardError from '../components/DashboardError';

const useStyles = makeStyles(() => ({
    gridFix: {
        margin: 0,
        width: '100%',
    },
}));

const PaymentsView = () => {
    const classes = useStyles();
    const { data, error } = useSWR('panel/orders');
    if (!data && !error)
        return (
            <DashboardPageContainer>
                <Typography variant="h1">
                    <Skeleton />
                </Typography>
                <Grid className={classes.gridFix} container spacing={3}>
                    <Grid item xs={12}>
                        <Skeleton height={440} />
                    </Grid>
                </Grid>
            </DashboardPageContainer>
        );
    if (!!error) {
        return <DashboardError error={error} />;
    }
    return (
        <DashboardPageContainer>
            <Box ml={2} mb={2} mt={2}>
                <Typography variant="h1">Zamówienia</Typography>
            </Box>

            <Grid className={classes.gridFix} container spacing={3}>
                <Grid item xl={12} lg={12} xs={12}>
                    <PaymentsTable data={[...data].reverse()}></PaymentsTable>
                </Grid>
            </Grid>
        </DashboardPageContainer>
    );
};

export default PaymentsView;

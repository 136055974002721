import { nanoid } from 'nanoid';

const CategoryReducer = (data, action) => {
    switch (action.type) {
        case 'REMOVE':
            return data.filter(el => el._id !== action.payload);
        case 'ADD':
            return [...data, { _id: '_' + nanoid(), category: action.payload, meals: [] }];
        case 'MOVE_UP':
            let pos = data.findIndex(el => el._id === action.payload);
            if (pos === 0) return data;
            const tmp = data[pos - 1];
            data[pos - 1] = data[pos];
            data[pos] = tmp;
            return data;
        case 'MOVE_DOWN':
            let pos2 = data.findIndex(el => el._id === action.payload);
            if (pos2 === data.length - 1) return data;
            const tmp2 = data[pos2 + 1];
            data[pos2 + 1] = data[pos2];
            data[pos2] = tmp2;
            return data;
        case 'EDIT':
            let id = action.payload._id;
            let newCat = action.payload.category;
            return data.map(cat =>
                cat._id === id
                    ? {
                          ...cat,
                          category: newCat,
                      }
                    : cat
            );
        default:
            return data;
    }
};

export default CategoryReducer;

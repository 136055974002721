import { Typography, makeStyles, Chip } from '@material-ui/core';
import React, { Fragment } from 'react';
import { getNamePL } from '../utils';

const useStyles = makeStyles(theme => ({
    appBar: {
        background: theme.palette.background.gradient,
    },
    navbarTitle: {
        marginLeft: theme.spacing(2),
        flex: 1,
        [theme.breakpoints.down('xs')]: {
            fontSize: '18px',
        },
    },
    chips: {
        marginTop: 16,
        marginBottom: 12,
    },
    item: {
        margin: '4px 8px 4px 0',
    },
    inner: {
        paddingTop: 75,
    },
    searchWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
    groupTitle: {
        marginTop: theme.spacing(3),
    },
    hideSm: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    hideLg: {
        [theme.breakpoints.up('xs')]: {
            display: 'none',
        },
    },
    searchOuter: {
        [theme.breakpoints.up('sm')]: {
            position: 'sticky',
            top: 100,
        },
    },
    searchColumn: {
        [theme.breakpoints.up('sm')]: {
            overflow: 'visible',
        },
    },
}));

const AllergensDialogGr = ({ element, cat, state, flipAllergen, changed }) => {
    const classes = useStyles();
    return (
        <Fragment key={cat}>
            <Typography className={classes.groupTitle} variant="h2">
                {getNamePL(element.label)}
            </Typography>
            <div className={classes.chips}>
                {Object.keys(element.members).map((el, idx) => {
                    return (
                        <Chip
                            onClick={() => {
                                flipAllergen(el);
                            }}
                            className={classes.item}
                            label={getNamePL(element.members[el])}
                            color={state.includes(el) ? 'primary' : 'default'}
                            key={el}
                        ></Chip>
                    );
                })}
            </div>
        </Fragment>
    );
};

const checkEquality = (prevProps, newProps) => {
    return !newProps.element.members.hasOwnProperty(newProps.changed);
};

export const AllergensDialogGroup = React.memo(AllergensDialogGr, checkEquality);

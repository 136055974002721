import { Chip, IconButton, makeStyles, TableCell, TableRow, Tooltip } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import {
    Add,
    ArrowDownward,
    ArrowUpward,
    CompareArrows,
    MoreHoriz,
    Remove,
} from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { getNameEN, getNamePL } from '../utils';
import { AllergensContext } from '../views/AllergensContext';

const useStyles = makeStyles(theme => ({
    cardTitleIcon: {
        verticalAlign: 'bottom',
        padding: '0 8px',
    },
    actionButton: {
        marginLeft: '12px',
    },
    actionCell: {
        [theme.breakpoints.up('md')]: { minWidth: '175px' },
        [theme.breakpoints.down('sm')]: { width: '88px' },
        [theme.breakpoints.down('xs')]: { width: '40px' },
    },
    nameCell: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '120px',
            paddingRight: 0,
        },
    },
    priceCell: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '60px',
            paddingRight: 0,
        },
        paddingLeft: '5px',
    },
    pl: {
        fontWeight: 500,
    },
    eng: {
        fontStyle: 'italic',
    },
    chip: {
        margin: '2px 2px',
    },
    removed: {
        textDecoration: 'line-through',
    },
    cardFooterBtn: {
        marginLeft: '12px',
        marginBottom: '12px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '8px',
        },
    },
    allergenActions: {
        display: 'inline-block',
    },
}));

const MealEditorRow = ({
    row,
    setAllergensEdited,
    setEdited,
    setDeleted,
    dispatchAction,
    isFirst,
    isLast,
}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const { allAllergensFlattened } = useContext(AllergensContext);
    return (
        <TableRow key={row._id}>
            <TableCell className={classes.nameCell} scope="row">
                <span className={classes.pl}>{getNamePL(row.name)}</span>
                <br />
                <span className={classes.eng}>{getNameEN(row.name)}</span>
            </TableCell>
            <TableCell align="right" className={classes.priceCell} scope="row">
                {!!row.price && <span>{row.price} zł</span>}
                {!row.price && '-'}
                {row.calories && (
                    <>
                        <br />
                        <span className={classes.eng}>{row.calories} kcal</span>
                    </>
                )}
            </TableCell>
            <TableCell align="right">
                {row.allergens.slice(0, expanded ? row.allergens.length : 3).map(allergen => (
                    <Chip
                        className={classes.chip}
                        size="small"
                        color="primary"
                        key={allergen}
                        label={getNamePL(allAllergensFlattened[allergen])}
                        icon={row.added?.has(allergen) ? <Add /> : null}
                    ></Chip>
                ))}
                {!!row.removed &&
                    (row.allergens.length < 3 || expanded) &&
                    Array.from(row.removed)
                        .slice(0, expanded ? row.removed.size : 3 - row.allergens.length)
                        .map(allergen => (
                            <Chip
                                className={classes.chip + ' ' + classes.removed}
                                size="small"
                                key={allergen}
                                label={getNamePL(allAllergensFlattened[allergen])}
                                icon={<Remove />}
                            ></Chip>
                        ))}
                {row.allergens.length + (row.removed?.size || 0) > 3 && !expanded && (
                    <Chip
                        className={classes.chip}
                        size="small"
                        label={'+' + (row.allergens.length - 3 + (row.removed?.size || 0))}
                    ></Chip>
                )}
                <div className={classes.allergenActions}>
                    {row.allergens.length + (row.removed?.size || 0) > 3 && !expanded && (
                        <Tooltip title="Rozwiń alergeny">
                            <IconButton
                                className={classes.actionButton}
                                size="small"
                                aria-label="down"
                                onClick={() => {
                                    setExpanded(true);
                                }}
                            >
                                <MoreHoriz />
                            </IconButton>
                        </Tooltip>
                    )}
                    {row.allergens.length + (row.removed?.size || 0) > 3 && expanded && (
                        <Tooltip title="Zwiń alergeny">
                            <IconButton
                                className={classes.actionButton}
                                size="small"
                                aria-label="down"
                                onClick={() => {
                                    setExpanded(false);
                                }}
                            >
                                <CompareArrows />
                            </IconButton>
                        </Tooltip>
                    )}
                    {row.allergens.length === 0 && (
                        <Tooltip title="Dodaj alergeny">
                            <IconButton
                                className={classes.actionButton}
                                size="small"
                                aria-label="down"
                                onClick={() => {
                                    setAllergensEdited(row);
                                }}
                            >
                                <Add />
                            </IconButton>
                        </Tooltip>
                    )}
                    {row.allergens.length > 0 && (
                        <Tooltip title="Edytuj alergeny">
                            <IconButton
                                className={classes.actionButton}
                                size="small"
                                aria-label="down"
                                onClick={() => {
                                    setAllergensEdited(row);
                                }}
                            >
                                <CreateIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </TableCell>
            <TableCell align="right" className={classes.actionCell}>
                <Tooltip enterNextDelay={500} title="Przenieś do góry">
                    <IconButton
                        disabled={isFirst}
                        className={classes.actionButton}
                        size="small"
                        aria-label="up"
                        onClick={() => {
                            dispatchAction({
                                type: 'MOVE_UP',
                                payload: row._id,
                            });
                        }}
                    >
                        <ArrowUpward />
                    </IconButton>
                </Tooltip>
                <Tooltip enterNextDelay={500} title="Przenieś w dół">
                    <IconButton
                        disabled={isLast}
                        className={classes.actionButton}
                        size="small"
                        aria-label="down"
                        onClick={() => {
                            dispatchAction({
                                type: 'MOVE_DOWN',
                                payload: row._id,
                            });
                        }}
                    >
                        <ArrowDownward />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edytuj danie">
                    <IconButton
                        className={classes.actionButton}
                        size="small"
                        aria-label="down"
                        onClick={() => {
                            setEdited(row);
                        }}
                    >
                        <CreateIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Usuń danie">
                    <IconButton
                        className={classes.actionButton}
                        size="small"
                        aria-label="down"
                        onClick={() => {
                            setDeleted(row._id);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default MealEditorRow;

import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export const BillingDataList = ({ data }) => (
    <List>
        <ListItem>
            <ListItemText primary={data.billingCompany || '-'} secondary="Nazwa firmy" />
        </ListItem>
        <ListItem>
            <ListItemText primary={data.billingNIP || '-'} secondary="Numer NIP" />
        </ListItem>
        <ListItem>
            <ListItemText primary={data.billingAddress || '-'} secondary="Adres" />
        </ListItem>
        <ListItem>
            <ListItemText
                primary={(data.billingPostCode || '-') + ' ' + (data.billingCity || '-')}
                secondary="Kod pocztowy i miasto"
            />
        </ListItem>
    </List>
);

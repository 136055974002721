import React, { useContext, useState } from 'react';
import DashboardPageContainer from '../layouts/partials/DashboardPageContainer';
import { Typography, Grid, Box, makeStyles, Link, Snackbar } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import CenteredError from '../components/CenteredError';
import { Link as RouterLink, useParams, Prompt } from 'react-router-dom';
import useAuthApiGet from '../hooks/useAuthApiGet';
import useAuthApiPost from '../hooks/useAuthApiPost';
import { useEffect } from 'react';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MealEditor from '../components/MealEditor';
import MealReducer from '../reducers/MealReducer';
import DashboardError from '../components/DashboardError';
import { AllergensContext } from './AllergensContext';

const useStyles = makeStyles(theme => ({
    gridFix: {
        margin: 0,
        width: '100%',
    },
    bottomSkeleton: {
        marginLeft: '12px',
    },
    snackbar: {
        background: 'white',
        color: theme.palette.text.primary,
        '& div': {
            textAlign: 'center',
            width: '100%',
        },
    },
    tooltipIcon: {
        verticalAlign: 'bottom',
        paddingRight: '10px',
    },
}));

const updateCategory = (data, id, meals) =>
    data.map(cat => (cat._id === id ? { ...cat, meals: meals } : cat));

const MealsView = () => {
    const classes = useStyles();
    const { id } = useParams();

    const { loaded } = useContext(AllergensContext);
    const { loading, error: loadingError, data, setData } = useAuthApiGet('panel/menu');
    const [touched, setTouched] = useState(false);
    const [notification, setNotification] = useState(false);
    const [open, setOpen] = useState(false);
    const {
        loading: postLoading,
        error: postError,
        callAPI,
        success,
        data: postData,
    } = useAuthApiPost('panel/menu');

    const activeCategory = data?.find(el => el._id === id);

    useEffect(() => {
        if (!!postError) {
            console.log(postError);
            setNotification({ type: 'ERROR', content: postError });
            setTouched(true);
            setOpen(true);
        } else if (!!success) {
            setNotification({ type: 'OK', content: 'Zapisano zmodyfikowane menu.' });
            setOpen(true);
            setData(postData);
        } else {
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postError, success, postLoading, postData]);

    if (!!loadingError) {
        return <DashboardError error={{ msg: loadingError }} />;
    }
    if (!loading && !loadingError && !activeCategory) {
        return (
            <CenteredError title="Wystąpił błąd">
                Nie znaleziono żądanej kategorii menu.
                <br />
                <Link to={'/menu'} component={RouterLink}>
                    Przejdź do ekranu kategorii
                </Link>
                .
            </CenteredError>
        );
    }
    return (
        <DashboardPageContainer>
            <Prompt
                when={!!touched}
                message="Posiadasz niezapisane zmiany w tej kategorii menu. Czy na pewno chcesz opuścić stronę bez zapisywania?"
            />
            <Box ml={2} mb={2} mt={2}>
                <Typography variant="h1">Dania w menu</Typography>
            </Box>
            <Grid className={classes.gridFix} container spacing={3}>
                <Grid item xs={12}>
                    {!!loading ||
                        (!loaded && (
                            <>
                                <Skeleton height={120} />
                                <Skeleton height={120} />
                                <Skeleton height={120} />
                                <Skeleton height={120} />
                                <Box display="flex">
                                    <Skeleton variant="circle" height={50} width={50} />
                                    <Skeleton
                                        height={50}
                                        width="20%"
                                        className={classes.bottomSkeleton}
                                    />
                                </Box>
                            </>
                        ))}
                    {!!activeCategory?.meals && !!loaded && (
                        <MealEditor
                            categoryName={activeCategory.category}
                            data={activeCategory.meals}
                            touched={touched}
                            saving={!!postLoading}
                            dispatchAction={action => {
                                setData(
                                    updateCategory(
                                        data,
                                        id,
                                        MealReducer(activeCategory.meals, action)
                                    )
                                );
                                setTouched(true);
                            }}
                            onSave={() => {
                                setTouched(false);
                                callAPI(data);
                            }}
                        ></MealEditor>
                    )}
                </Grid>
            </Grid>
            <Snackbar
                ContentProps={{
                    classes: { root: classes.snackbar },
                }}
                open={open}
                autoHideDuration={3000}
                onClose={(event, reason) => {
                    setOpen(false);
                }}
                message={
                    notification.type === 'ERROR' ? (
                        <>
                            <ErrorOutlineIcon
                                className={classes.tooltipIcon}
                                color="primary"
                            />{' '}
                            {notification.content}{' '}
                        </>
                    ) : (
                        <>
                            <DoneIcon className={classes.tooltipIcon} color="primary" />{' '}
                            {notification.content}{' '}
                        </>
                    )
                }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </DashboardPageContainer>
    );
};

export default MealsView;

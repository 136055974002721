import {
    AppBar,
    Dialog,
    IconButton,
    Typography,
    Button,
    Toolbar,
    Slide,
    makeStyles,
    Container,
    Grid,
} from '@material-ui/core';
import { Close, Done } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { getNamePL, truncateLabel } from '../utils';
import SearchAllergens from './SearchAllergens';
import { AllergensDialogGroup } from './AllergensDialogGroup';
import { Skeleton } from '@material-ui/lab';
import { AllergensContext } from '../views/AllergensContext';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    appBar: {
        background: theme.palette.background.gradient,
    },
    navbarTitle: {
        marginLeft: theme.spacing(2),
        flex: 1,
        [theme.breakpoints.down('xs')]: {
            fontSize: '18px',
        },
    },
    chips: {
        marginTop: 16,
        marginBottom: 12,
    },
    item: {
        margin: '4px 8px 4px 0',
    },
    inner: {
        paddingTop: 75,
    },
    searchWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
    groupTitle: {
        marginTop: theme.spacing(3),
    },
    hideSm: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    hideLg: {
        [theme.breakpoints.up('xs')]: {
            display: 'none',
        },
    },
    searchOuter: {
        [theme.breakpoints.up('sm')]: {
            position: 'sticky',
            top: 100,
        },
    },
    searchColumn: {
        [theme.breakpoints.up('sm')]: {
            overflow: 'visible',
        },
    },
}));

const AllergensDialog = ({ open, setClosed, mealName, updateAllergens, allergens }) => {
    const { allergensRaw: allAllergens } = useContext(AllergensContext);
    const [state, setState] = useState(allergens);
    const [display, setDisplay] = useState(false);
    const [changed, setChanged] = useState('');
    const classes = useStyles();

    useEffect(() => {
        if (!!open) {
            setState(allergens);
            setDisplay(false);
            var timer1 = setTimeout(() => setDisplay(true), 100);
        }
        return () => {
            clearTimeout(timer1);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const flipAllergen = allergen => {
        console.log(allergen);
        if (state.includes(allergen)) {
            setState(s => s.filter(a => a !== allergen));
        } else {
            setState(s => [...s, allergen]);
        }
        setChanged(allergen);
    };

    return (
        <Dialog fullScreen open={open} onClose={setClosed} TransitionComponent={Transition}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={setClosed}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography className={classes.navbarTitle} variant="h3">
                        Alergeny:{' '}
                        <span className={classes.hideLg}>
                            {truncateLabel(getNamePL(mealName), 30)}
                        </span>
                        <span className={classes.hideSm}>{getNamePL(mealName)}</span>
                    </Typography>
                    <Button
                        autoFocus
                        color="inherit"
                        onClick={() => {
                            updateAllergens(state);
                        }}
                        endIcon={<Done />}
                    >
                        <span className={classes.hideSm}>Zatwierdź</span>
                    </Button>
                </Toolbar>
            </AppBar>
            <Container className={classes.inner} maxWidth="lg">
                <Grid container>
                    <Grid item xs={12} md={8}>
                        {!display && (
                            <>
                                <Skeleton style={{ marginTop: 18 }} height={50} />
                                <Skeleton variant="circle" height={30} width={30} />
                                <Skeleton style={{ marginTop: 18 }} height={50} />
                                <Skeleton variant="circle" height={30} width={30} />
                            </>
                        )}
                        {display &&
                            Object.keys(allAllergens).map((cat, idx) => {
                                const element = allAllergens[cat];

                                return (
                                    <AllergensDialogGroup
                                        key={cat}
                                        element={element}
                                        cat={cat}
                                        state={state}
                                        flipAllergen={allergen => {
                                            flipAllergen(allergen);
                                        }}
                                        changed={changed}
                                    />
                                );
                            })}
                    </Grid>
                    <Grid className={classes.searchColumn} item xs={12} md={4}>
                        <div className={classes.searchOuter}>
                            <SearchAllergens
                                open={open}
                                state={state}
                                flipAllergen={allergen => {
                                    flipAllergen(allergen);
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
};

export default AllergensDialog;

import React, { useState, useContext } from 'react';
import { UserContext } from '../UserContext';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import jwtDecoder from 'jwt-decode';

import {
    Box,
    Button,
    Link,
    TextField,
    Typography,
    CircularProgress,
    //makeStyles
} from '@material-ui/core';

import { Alert } from '@material-ui/lab';
import VerticallyCenteredSubLayout from '../layouts/VerticallyCenteredSubLayout';

const LoginView = () => {
    const { register, handleSubmit } = useForm();

    const [state, setState] = useState({ submitting: false, error: null });

    const history = useHistory();
    const location = useLocation();

    const { user, setUser } = useContext(UserContext);

    const apiLoginCall = async (email, password) => {
        setState({ ...state, submitting: true });
        fetch(process.env.REACT_APP_API_ADDR + 'auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email, password: password }),
        })
            .then(async response => {
                const data = await response.json();
                if (!response.ok) {
                    setState({ submitting: false, error: data.error });
                } else {
                    setState({
                        ...state,
                        submitting: false,
                        error: null,
                        loggedOut: false,
                    });
                    const decoded = jwtDecoder(data.token);
                    setUser({ ...decoded, token: data.token });
                    localStorage.setItem('token', data.token);
                    if (location.search.includes('renew')) {
                        history.push('/payments/new');
                    } else {
                        history.push('/dashboard');
                    }
                }
            })
            .catch(error => {
                setState({
                    submitting: false,
                    error:
                        'Wystąpił błąd sieciowy. Prosimy upewnić się że urządzenie jest w trybie online i spróbowac ponownie.',
                });
            });
    };

    return (
        <VerticallyCenteredSubLayout>
            <img
                style={{ width: '70%', margin: '0 auto', display: 'block' }}
                src="/foodinly_logo_crop.svg"
                alt="logo foodinly"
            ></img>
            <form
                onSubmit={handleSubmit(data => {
                    apiLoginCall(data.email, data.password);
                })}
            >
                <Box mb={3}>
                    <Typography color="textPrimary" variant="h2" align="center">
                        Logowanie w Aplikacji
                    </Typography>
                </Box>
                <Box mt={3} mb={1}>
                    <Typography align="center" color="textSecondary" variant="body1">
                        Zaloguj się, aby zarządzać swoim lokalem gastronomicznym
                    </Typography>
                    {state.error && (
                        <Box mt={3} mb={1}>
                            <Alert severity="error">
                                {state.error}{' '}
                                {!state.error?.includes('zawieszone') && (
                                    <>
                                        Masz problem z dostępem do konta? Skorzystaj z{' '}
                                        <Link component={RouterLink} to="/reset" variant="h6">
                                            odzyskiwania hasła
                                        </Link>
                                        .
                                    </>
                                )}
                            </Alert>
                        </Box>
                    )}
                    {user?.justLoggedOut && !state.error && (
                        <Box mt={3} mb={1}>
                            <Alert severity="success">
                                Wylogowano poprawnie. Zapraszamy ponownie!
                            </Alert>
                        </Box>
                    )}
                    {user?.permissionsError && (
                        <Box mt={3} mb={1}>
                            <Alert severity="error">
                                Wykryto niedozwolone działanie. Wylogowano.
                            </Alert>
                        </Box>
                    )}
                </Box>
                <TextField
                    fullWidth
                    label="Adres e-mail"
                    margin="normal"
                    name="email"
                    type="email"
                    variant="outlined"
                    inputRef={register}
                    required={true}
                />
                <TextField
                    fullWidth
                    label="Hasło"
                    margin="normal"
                    name="password"
                    type="password"
                    variant="outlined"
                    inputRef={register}
                    required={true}
                />
                <Box my={2}>
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        disabled={state.submitting}
                    >
                        Zaloguj się{' '}
                        {state.submitting && <CircularProgress size={16}></CircularProgress>}
                    </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                    Nie masz jeszcze konta?{' '}
                    <Link component={RouterLink} to="/register" variant="h6">
                        Zarejestruj się
                    </Link>{' '}
                    |{' '}
                    <Link component={RouterLink} to="/reset" variant="h6">
                        Odzyskaj hasło
                    </Link>{' '}
                </Typography>
            </form>
        </VerticallyCenteredSubLayout>
    );
};

export default LoginView;

import React, { useContext } from 'react';
import { useState } from 'react';
import useSWR from 'swr';
import DashboardPageContainer from '../layouts/partials/DashboardPageContainer';
import {
    Typography,
    Grid,
    Box,
    makeStyles,
    withStyles,
    Collapse,
    Button,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    CircularProgress,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    InputAdornment,
    IconButton,
    Divider,
} from '@material-ui/core';
import StatCards from '../components/StatCards';
import Skeleton from '@material-ui/lab/Skeleton';
import ScansChart from '../components/ScansChart';
import BillingDataCard from '../components/BillingDataCard';
import LastOrdersCard from '../components/LastOrdersCard';
import DashboardError from '../components/DashboardError';
import Fingerprint from '@material-ui/icons/Fingerprint';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Alert } from '@material-ui/lab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import NavigateBeforeIcon from '@material-ui/icons/ArrowUpwardRounded';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PageViewIcon from '@material-ui/icons/Pageview';
import { UserContext } from '../UserContext';
import { readableFileSize } from '../utils';
import { Storefront } from '@material-ui/icons';
import { useHistory } from 'react-router';

const styles = {
    root: {
        marginLeft: 10,
    },
};

const useStyles = makeStyles(theme => ({
    gridFix: {
        margin: 0,
        width: '100%',
    },
    formControl: {
        width: '100%',
        margin: theme.spacing[1],
    },
    label: {
        color: '#546e7a',
        marginTop: '4px',
        paddingLeft: '90px',
        zIndex: 300,
    },
    select: {
        background: 'white',
        boxShadow: theme.shadows[1],
        '&:hover': {
            backgroundColor: '#eee !important',
        },
    },
}));

const SpinnerAdornment = withStyles(styles)(props => (
    <CircularProgress
        style={{ marginLeft: '10px' }}
        className={props.classes.spinner}
        size={20}
    />
));

const DashboardView = () => {
    const classes = useStyles();
    const { data: orders } = useSWR('panel/orders');
    const [notificationOpen, setNotificationOpen] = useState(true);
    const [step, setStep] = useState(0);
    const [kycModalOpen, setKycModalOpen] = useState(false);
    const [fileChosen, setFileChosen] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [restaurant, setRestaurant] = useState('');
    const { data, error, mutate } = useSWR(`panel/me?restaurant=${restaurant}`);
    const { user } = useContext(UserContext);
    const history = useHistory();

    if (!data && !error)
        return (
            <DashboardPageContainer>
                <Typography variant="h1">
                    <Skeleton />
                </Typography>
                <Grid className={classes.gridFix} container spacing={3}>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <Skeleton height={200} />
                    </Grid>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <Skeleton height={200} />
                    </Grid>
                    <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <Skeleton height={200} />
                    </Grid>
                </Grid>
                <Grid className={classes.gridFix} container spacing={3}>
                    <Grid item xs={12}>
                        <Skeleton height={440} />
                    </Grid>
                </Grid>
            </DashboardPageContainer>
        );
    if (!!error) {
        return <DashboardError error={error} />;
    }
    return (
        <DashboardPageContainer>
            <Dialog
                open={kycModalOpen}
                onClose={() => {
                    setKycModalOpen(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <AssignmentIcon color="primary" style={{ verticalAlign: '-15%' }} />{' '}
                    Uzupełnij dokumenty
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Potrzebujemy dopełnić jeszcze jedną formalność, aby umożliwić Twojemu
                        kontu zakup usług w aplikacji Foodinly. Pobierz wzór oświadczenia w
                        formacie PDF, a następnie prześlij jego zdjęcie lub skan podpisany
                        przez osoby uprawnione do reprezentowania podmiotu prowadzącego Twój
                        lokal gastronomiczny.
                    </DialogContentText>
                    <Stepper activeStep={step} orientation="vertical">
                        <Step>
                            <StepLabel>Pobierz wzór oświadczenia</StepLabel>
                            <StepContent>
                                <Button
                                    style={{ marginTop: '10px' }}
                                    download
                                    href={
                                        process.env.REACT_APP_API_ADDR +
                                        data?.restaurant?.docURL
                                    }
                                    onClick={() => {
                                        setStep(1);
                                    }}
                                    color="primary"
                                    endIcon={<GetAppIcon />}
                                >
                                    Pobierz PDF
                                </Button>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>
                                Prześlij skan lub zdjęcie podpisanego oświadczenia
                            </StepLabel>
                            <StepContent>
                                {!!fileChosen && (
                                    <p>
                                        Wybrano: {fileChosen.name} (
                                        {readableFileSize(fileChosen.size)})
                                    </p>
                                )}
                                {!!uploadError && (
                                    <p>
                                        Wystąpił błąd podczas przesyłania dokumentów. Spróbuj
                                        ponownie później.
                                    </p>
                                )}
                                <input
                                    accept="image/png,image/jpeg,image/jpg,application/pdf"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                    onChange={e => {
                                        if (e.target.files[0]) {
                                            const name = e.target.files[0].name;
                                            const exts = [
                                                '.pdf',
                                                '.PDF',
                                                '.jpg',
                                                '.JPG',
                                                '.JPEG',
                                                '.jpeg',
                                                '.PNG',
                                                '.png',
                                            ];

                                            for (const ext of exts) {
                                                if (name.endsWith(ext)) {
                                                    setFileChosen(e.target.files[0]);
                                                    return;
                                                }
                                            }
                                        }
                                        setFileChosen(null);
                                    }}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button
                                        variant={!fileChosen ? 'contained' : 'outlined'}
                                        style={{ marginTop: '10px', marginRight: '10px' }}
                                        component="span"
                                        color="primary"
                                        className={classes.button}
                                        endIcon={<AttachFileIcon />}
                                        disabled={uploading}
                                    >
                                        Wybierz plik
                                    </Button>
                                </label>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: '10px' }}
                                    onClick={() => {
                                        setUploading(true);
                                        setUploadError(false);
                                        var fd = new FormData();
                                        fd.append('document', fileChosen);

                                        fetch(
                                            process.env.REACT_APP_API_ADDR + 'panel/sendkyc',
                                            {
                                                method: 'POST',
                                                body: fd,
                                                headers: {
                                                    Authorization: `Bearer ${user.token}`,
                                                },
                                            }
                                        )
                                            .then(res => {
                                                if (res.status === 200) {
                                                    setKycModalOpen(false);
                                                    setNotificationOpen(true);
                                                    mutate({
                                                        ...data,
                                                        kycDocumentSent: Date.now(),
                                                    });
                                                } else {
                                                    setUploading(false);
                                                    setUploadError(true);
                                                }
                                            })
                                            .catch(err => {
                                                console.log(err);
                                                setUploading(false);
                                                setUploadError(true);
                                            });
                                    }}
                                    endIcon={!uploading ? <PublishIcon /> : null}
                                    disabled={!fileChosen || uploading}
                                >
                                    Prześlij {uploading && <SpinnerAdornment />}
                                </Button>
                                <br />
                                <Button
                                    variant="text"
                                    color="primary"
                                    style={{ marginTop: '10px' }}
                                    onClick={() => {
                                        setStep(0);
                                    }}
                                    endIcon={<NavigateBeforeIcon />}
                                    disabled={uploading}
                                >
                                    Wróć
                                </Button>
                            </StepContent>
                        </Step>
                    </Stepper>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
            {data.kycStatus === 1 && (
                <Collapse in={notificationOpen}>
                    <Alert
                        icon={<Fingerprint fontSize="inherit" />}
                        onClose={() => {
                            setNotificationOpen(false);
                        }}
                        severity="error"
                    >
                        Twoje konto jest w trakcie procesu weryfikacji. Do momentu jego
                        zakończenia nie będzie możliwe składanie zamówień na usługi.
                    </Alert>
                </Collapse>
            )}
            {data.kycStatus === 2 && !data.kycDocumentSent && (
                <Alert
                    icon={<AssignmentIcon fontSize="inherit" />}
                    severity="error"
                    action={
                        <Button
                            color="inherit"
                            size="smal"
                            onClick={() => {
                                setKycModalOpen(true);
                            }}
                        >
                            Uzupełnij dokumenty
                        </Button>
                    }
                >
                    Potrzebujemy dodatkowych dokumentów, aby w pełni aktywować Twoje konto.
                </Alert>
            )}
            {data.kycStatus === 2 && !!data.kycDocumentSent && (
                <Collapse in={notificationOpen}>
                    <Alert
                        icon={<PageViewIcon fontSize="inherit" />}
                        onClose={() => {
                            setNotificationOpen(false);
                        }}
                        severity="error"
                    >
                        Weryfikujemy przesłane dokumenty. Po pozytywnym zakończeniu procesu
                        weryfikacji, Twoje konto będzie mogło składać zamówienia na usługi.
                    </Alert>
                </Collapse>
            )}
            <Grid className={classes.gridFix} container spacing={3}>
                <Grid item lg={8} sm={12} xl={8} xs={12}>
                    <Typography variant="h1">Pulpit Lokalu Gastronomicznego</Typography>
                </Grid>
                {data?.restaurantType === 'chain' && (
                    <Grid item lg={4} sm={12} xl={4} xs={12}>
                        <Box width="100%">
                            <FormControl className={classes.formControl}>
                                <InputLabel className={classes.label}>
                                    Wybierz lokal
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={
                                        restaurant === '' ? data.restaurant.codeId : restaurant
                                    }
                                    variant="filled"
                                    className={classes.select}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    history.push('/premises');
                                                }}
                                            >
                                                <Storefront />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    disableUnderline
                                    onChange={e => {
                                        setRestaurant(e.target.value);
                                    }}
                                >
                                    {data.otherRestaurants.map(r => (
                                        <MenuItem key={r.codeId} value={r.codeId}>
                                            {r.name}
                                        </MenuItem>
                                    ))}
                                    <Divider />
                                    <MenuItem
                                        value={''}
                                        onClick={() => {
                                            history.push('/premises');
                                        }}
                                    >
                                        Zarządzaj lokalami
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                )}
            </Grid>
            <Grid className={classes.gridFix} container spacing={3}>
                <StatCards data={data}></StatCards>
            </Grid>

            <Grid className={classes.gridFix} container spacing={3}>
                <Grid item xl={12} lg={12} xs={12}>
                    <ScansChart stats={data.restaurant.stats}></ScansChart>
                </Grid>
                <Grid item xl={6} lg={6} md={6} xs={12}>
                    <BillingDataCard data={data}></BillingDataCard>
                </Grid>
                <Grid item xl={6} lg={6} md={6} xs={12}>
                    <LastOrdersCard
                        orders={orders
                            ?.filter(el => !!el.invoiceId)
                            .reverse()
                            .slice(0, 4)}
                    ></LastOrdersCard>
                </Grid>
            </Grid>
        </DashboardPageContainer>
    );
};

export default DashboardView;

import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Box, Link, Typography, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import VerticallyCenteredSubLayout from '../layouts/VerticallyCenteredSubLayout';

const ActivateAccountView = () => {
    const [state, setState] = useState({
        submitting: true,
        errror: null,
        success: null,
    });

    const { token } = useParams();

    useEffect(() => {
        fetch(process.env.REACT_APP_API_ADDR + 'auth/verify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ verifyToken: token }),
        })
            .then(async response => {
                if (response.status !== 200) {
                    setState(state => ({
                        ...state,
                        submitting: false,
                        error:
                            'Wystąpił błąd podczas aktywacji konta. Link aktywacyjny jest nieważny. Możliwe, że konto zostało już wcześniej aktywowane.',
                        success: null,
                    }));
                } else {
                    setState(state => ({
                        ...state,
                        submitting: false,
                        error: null,
                        success: true,
                    }));
                }
            })
            .catch(error => {
                setState(state => ({
                    ...state,
                    submitting: false,
                    success: null,
                    error:
                        'Wystąpił błąd sieciowy. Prosimy upewnić się że urządzenie jest w trybie online i spróbować ponownie.',
                }));
            });
    }, [token]);

    return (
        <VerticallyCenteredSubLayout>
            <img
                style={{ width: '65%', margin: '0 auto', display: 'block' }}
                src="/foodinly_logo_crop.svg"
                alt="logo foodinly"
            ></img>
            <Box mt={3} mb={3}>
                <Typography color="textPrimary" variant="h2" align="center">
                    Aktywacja konta Foodinly
                </Typography>
            </Box>
            <Box mt={3} mb={1}>
                <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                >
                    Dziękujemy za rejestrację w aplikacji.
                </Typography>
                {!!state.error && (
                    <Box mt={3} mb={3}>
                        <Alert severity="error">{state.error}</Alert>
                    </Box>
                )}
                {!!state.success && (
                    <Box mt={3} mb={3}>
                        <Alert severity="success">
                            Aktywacja konta powiodła się. Aby zalogować się w
                            aplikacji, przejdź do{' '}
                            <Link component={RouterLink} to="/" variant="h6">
                                ekranu logowania
                            </Link>
                            .
                        </Alert>
                    </Box>
                )}
                {state.submitting && (
                    <Box mt={3} mb={3} textAlign="center">
                        <CircularProgress></CircularProgress>
                        <Typography
                            color="textPrimary"
                            variant="body2"
                            align="center"
                            style={{ marginTop: '16px' }}
                        >
                            Trwa aktywacja konta...
                        </Typography>
                    </Box>
                )}
            </Box>
            <Typography color="textSecondary" variant="body1">
                Powrót do{' '}
                <Link component={RouterLink} to="/" variant="h6">
                    strony głównej
                </Link>
            </Typography>
        </VerticallyCenteredSubLayout>
    );
};

export default ActivateAccountView;

import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import React, { useState } from 'react';

const SearchBox = ({ updateFilter }) => {
    const [value, setValue] = useState('');
    const clearInput = () => {
        setValue('');
        updateFilter('');
    };

    return (
        <Grid container spacing={1} alignItems="flex-end" justify="center">
            <Grid item>
                <Search color="primary" />
            </Grid>
            <Grid item>
                <TextField
                    id="input-with-icon-grid"
                    label="Szukaj alergenu"
                    value={value}
                    onChange={e => {
                        setValue(e.target.value);
                        updateFilter(e.target.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={clearInput}>
                                    <Clear
                                        style={{
                                            visibility:
                                                !!value && value !== '' ? 'visible' : 'hidden',
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default SearchBox;

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import { ArrowBack } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import { getNamePL } from '../utils';
import { Link as RouterLink } from 'react-router-dom';
import AllergensDialog from './AllergensDialog';
import MealEditorRow from './MealEditorRow';
import { MealDialog } from './MealDialog';

export const normalizePrice = value => {
    if (value === '') {
        return '';
    }
    let normalized = value.replace(/\D/g, '').replace(/\b0+/g, '');
    const len = normalized.length;
    if (len === 0) {
        return '0.00';
    }
    if (len === 1) {
        return '0.0' + normalized;
    }
    if (len === 2) {
        return '0.' + normalized;
    }
    return normalized.slice(0, len - 2) + '.' + normalized.slice(len - 2, len);
};

const useStyles = makeStyles(theme => ({
    cardTitleIcon: {
        verticalAlign: 'bottom',
        padding: '0 8px',
    },
    cardButton: {
        margin: '10px 0 0 10px ',
        [theme.breakpoints.down('xs')]: {
            marginTop: '20px',
        },
    },
    cardHeader: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    cardNoPaddingResponsive: {
        [theme.breakpoints.down('xs')]: {
            padding: '5px 0',
        },
    },
    cardFooterBtn: {
        marginLeft: '12px',
        marginBottom: '12px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '8px',
        },
    },
    inputRight: {
        textAlign: 'right',
    },
}));

const MealEditor = ({ categoryName, saving, touched, data, dispatchAction, onSave }) => {
    const classes = useStyles();
    const [deleted, setDeleted] = useState(null);
    const [edited, setEdited] = useState(null);
    const [adding, setAdding] = useState(false);
    const [allergensEdited, setAllergensEdited] = useState(null);

    return (
        <>
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    title={
                        <>
                            <RestaurantMenuIcon
                                color="primary"
                                className={classes.cardTitleIcon}
                            />
                            Zarządzanie daniami kategorii: {getNamePL(categoryName)}
                        </>
                    }
                    action={
                        <div>
                            <Button
                                endIcon={<SaveIcon />}
                                variant="contained"
                                size="small"
                                color="primary"
                                disabled={!touched || !!saving}
                                className={
                                    classes.cardButton + ' ' + classes.cardButtonResponsive
                                }
                                onClick={() => {
                                    onSave();
                                }}
                            >
                                Zapisz zmiany
                            </Button>
                            <Button
                                endIcon={<AddIcon />}
                                variant="contained"
                                size="small"
                                color="primary"
                                className={classes.cardButton}
                                onClick={() => {
                                    setAdding(true);
                                }}
                            >
                                Utwórz danie
                            </Button>
                        </div>
                    }
                />
                <CardContent className={classes.cardNoPaddingResponsive}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nazwa dania</TableCell>
                                    <TableCell align="right">Cena</TableCell>
                                    <TableCell align="right">Alergeny</TableCell>
                                    <TableCell align="right">Akcje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, idx) => (
                                    <MealEditorRow
                                        row={row}
                                        key={row._id}
                                        setAllergensEdited={row => {
                                            setAllergensEdited(row);
                                        }}
                                        setDeleted={row => {
                                            setDeleted(row);
                                        }}
                                        setEdited={row => {
                                            setEdited(row._id);
                                        }}
                                        isFirst={idx === 0}
                                        isLast={idx === data.length - 1}
                                        dispatchAction={action => {
                                            dispatchAction(action);
                                        }}
                                    />
                                ))}
                                {(!data || data.length === 0) && (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Typography variant="body2" align="center">
                                                Nie wprowadzono żadnych dań w edytowanej
                                                kategorii. Naciśnij przycisk "Utwórz Danie" aby
                                                utworzyć nowe danie, do którego będzie można
                                                przypisać alergeny.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <Button
                    component={RouterLink}
                    to="/meals"
                    variant="text"
                    startIcon={<ArrowBack />}
                    className={classes.cardFooterBtn}
                >
                    Wróć do listy kategorii
                </Button>
            </Card>

            <Dialog open={!!deleted}>
                <DialogTitle id="alert-dialog-title">Usuwanie dania</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Czy na pewno chcesz usunąć danie:{' '}
                        {getNamePL(data?.find(el => el._id === deleted)?.name)}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDeleted(null);
                        }}
                    >
                        Anuluj
                    </Button>
                    <Button
                        onClick={() => {
                            dispatchAction({ type: 'REMOVE', payload: deleted });
                            setDeleted(null);
                        }}
                        color="primary"
                        autoFocus
                    >
                        Usuń danie
                    </Button>
                </DialogActions>
            </Dialog>
            <MealDialog
                edited={adding}
                meal={null}
                setEdited={() => {
                    setAdding(false);
                }}
                dispatchAction={dispatchAction}
                forCreation={true}
                categoryName={categoryName}
            />
            <MealDialog
                edited={edited}
                meal={data.filter(e => e._id === edited)[0]}
                setEdited={setEdited}
                dispatchAction={dispatchAction}
                categoryName={categoryName}
            />

            <AllergensDialog
                open={!!allergensEdited}
                setClosed={() => setAllergensEdited(null)}
                mealName={!!allergensEdited ? allergensEdited.name : '|'}
                updateAllergens={newAllergens => {
                    dispatchAction({
                        type: 'EDIT_ALLERGENS',
                        payload: { _id: allergensEdited._id, allergens: newAllergens },
                    });
                    setAllergensEdited(null);
                }}
                allergens={allergensEdited?.allergens || []}
            />
        </>
    );
};

export default MealEditor;

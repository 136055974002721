import React, { useEffect } from 'react';
import { useState, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import AppLayout from './layouts/AppLayout';
import LoginView from './views/LoginView';
import './App.css';
import View404 from './views/404';
import DashboardView from './views/DashboardView';
import { UserContext } from './UserContext';
import jwtDecoder from 'jwt-decode';
import TermsAndConditionsView from './views/TermsAndConditionsView';
import PasswordResetView from './views/PasswordResetView';
import PaymentResultView from './views/PaymentResultView';
import RegistrationFinishView from './views/RegistrationFinishView';
import ActivateAccountView from './views/ActivateAccountView';
import PasswordResetFinalizeView from './views/PasswordResetFinalizeView';
import DashboardLayout from './layouts/DashboardLayout';
import SupportView from './views/SupportView';
import { SWRConfig } from 'swr';
import PaymentsView from './views/PaymentsView';
import NewPaymentView from './views/NewPaymentView';
import SettingsView from './views/SettingsView';
import MenuCategoryView from './views/MenuCategoryView';
import MealsView from './views/MealsView';
import MenuView from './views/MenuView';
import { AllergensContext } from './views/AllergensContext';
import RegisterView from './views/RegisterView';
import PremisesView from './views/PremisesView';

function App() {
    const [user, setUser] = useState(() => {
        const token = localStorage.getItem('token');
        if (token === null) {
            return { loggedOut: true };
        } else {
            const decoded = jwtDecoder(token);
            return { ...decoded, token: token, loggedOut: false };
        }
    });
    const value = useMemo(() => ({ user, setUser }), [user, setUser]);

    const [allergensData, setAllergens] = useState(() => ({ loaded: false }));

    useEffect(() => {
        fetch(process.env.REACT_APP_API_ADDR + 'api/allergens').then(async response => {
            const allergens = await response.json();
            const allGroups = new Set(Object.keys(allergens));

            const getAllAllergens = () => {
                let a = [];
                for (var key in allergens) {
                    if (allergens.hasOwnProperty(key)) {
                        for (var key2 in allergens[key].members) {
                            if (allergens[key].members.hasOwnProperty(key2)) {
                                a.push(key2);
                            }
                        }
                    }
                }
                return a;
            };

            const allAllergens = new Set(getAllAllergens());

            const allAllergensFlattened = {};

            for (var key in allergens) {
                if (allergens.hasOwnProperty(key)) {
                    for (var key2 in allergens[key].members) {
                        if (allergens[key].members.hasOwnProperty(key2)) {
                            allAllergensFlattened[key2] = allergens[key].members[key2];
                        }
                    }
                }
            }

            const allAllergensFlattenedKeys = Object.keys(allAllergensFlattened);

            setAllergens({
                loaded: true,
                allergensRaw: allergens,
                allAllergens,
                allAllergensFlattened,
                allAllergensFlattenedKeys,
                allGroups,
            });
        });
    }, []);

    return (
        <SWRConfig
            value={{
                fetcher: async url => {
                    const response = await fetch(process.env.REACT_APP_API_ADDR + url, {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    const data = await response.json();
                    if (response.ok) {
                        return data;
                    } else {
                        if (response.status === 403) {
                            localStorage.removeItem('token');
                            setUser({
                                loggedOut: true,
                                permissionsError: true,
                            });
                        }
                        const error = new Error('Wystąpił błąd podczas obsługi zapytania.');
                        error.status = response.status;
                        error.msg = data.error;
                        throw error;
                    }
                },
            }}
        >
            <UserContext.Provider value={value}>
                <AllergensContext.Provider value={allergensData}>
                    <Switch>
                        <Route exact path="/regulamin">
                            <AppLayout>
                                <TermsAndConditionsView></TermsAndConditionsView>
                            </AppLayout>
                        </Route>
                        <Route exact path="/reset">
                            <AppLayout>
                                <PasswordResetView></PasswordResetView>
                            </AppLayout>
                        </Route>
                        <Route exact path="/registration-done">
                            <AppLayout>
                                <RegistrationFinishView></RegistrationFinishView>
                            </AppLayout>
                        </Route>
                        <Route path="/resetfinalize/:token">
                            <AppLayout>
                                <PasswordResetFinalizeView></PasswordResetFinalizeView>
                            </AppLayout>
                        </Route>
                        <Route path="/activate/:token">
                            <AppLayout>
                                <ActivateAccountView></ActivateAccountView>
                            </AppLayout>
                        </Route>
                        <Route path="/register">
                            <AppLayout>
                                <RegisterView></RegisterView>
                            </AppLayout>
                        </Route>
                        <Route exact path="/">
                            <AppLayout>
                                <LoginView></LoginView>
                            </AppLayout>
                        </Route>
                        <Route exact path="/dashboard">
                            <DashboardLayout>
                                <DashboardView></DashboardView>
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/premises">
                            <DashboardLayout>
                                <PremisesView></PremisesView>
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/payments/new">
                            <DashboardLayout>
                                <NewPaymentView />
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/payments">
                            <DashboardLayout>
                                <PaymentsView></PaymentsView>
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/menu">
                            <DashboardLayout>
                                <MenuCategoryView></MenuCategoryView>
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/meals">
                            <DashboardLayout>
                                <MenuView></MenuView>
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/meals/:id">
                            <DashboardLayout>
                                <MealsView></MealsView>
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/support">
                            <DashboardLayout>
                                <SupportView></SupportView>
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/payments/result">
                            <DashboardLayout>
                                <PaymentResultView></PaymentResultView>
                            </DashboardLayout>
                        </Route>
                        <Route exact path="/settings">
                            <DashboardLayout>
                                <SettingsView></SettingsView>
                            </DashboardLayout>
                        </Route>
                        <Route path="*">
                            <AppLayout preventRedirect={true}>
                                <View404></View404>
                            </AppLayout>
                        </Route>
                    </Switch>
                </AllergensContext.Provider>
            </UserContext.Provider>
        </SWRConfig>
    );
}

export default App;

import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
    Box,
    Button,
    Link,
    TextField,
    Typography,
    Checkbox,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Collapse,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import VerticallyCenteredSubLayout from '../layouts/VerticallyCenteredSubLayout';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const RegisterView = () => {
    const { register, handleSubmit, errors, control } = useForm();
    const [state, setState] = useState({
        submitting: false,
        error: null,
        message: null,
    });
    const history = useHistory();

    const apiSMSCall = data => {
        const oldData = data;
        setState({ ...state, submitting: true, message: null, error: null });
        fetch(process.env.REACT_APP_API_ADDR + 'auth/sms', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone: data.phone }),
        })
            .then(async response => {
                if (response.status !== 200) {
                    const data = await response.json();
                    setState({
                        submitting: false,
                        error: data.error ? data.error : data.errors,
                        message: null,
                    });
                } else {
                    setState({
                        ...state,
                        submitting: false,
                        error: null,
                        message: null,
                    });
                    setOtpModal(oldData);
                }
            })
            .catch(error => {
                setState({
                    ...state,
                    submitting: false,
                    error:
                        'Wystąpił błąd sieciowy. Prosimy upewnić się że urządzenie jest w trybie online i spróbować ponownie.',
                });
            });
    };

    const apiRegisterCall = data => {
        setState({ ...state, submitting: true, message: null, error: null });
        delete data.policy;
        fetch(process.env.REACT_APP_API_ADDR + 'auth/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...data }),
        })
            .then(async response => {
                if (response.status !== 201) {
                    const data = await response.json();
                    setState({
                        submitting: false,
                        error: data.error ? data.error : data.errors,
                        message: null,
                    });
                    setOtpModal(null);
                    setOtp('');
                } else {
                    setState({
                        ...state,
                        submitting: false,
                        error: null,
                        message:
                            'Rozpoczęto procedurę rejestracji. Na podany adres e-mail została wysłana wiadomość z linkiem aktywacyjnym.',
                    });
                    history.push('/registration-done');
                }
            })
            .catch(error => {
                setState({
                    ...state,
                    submitting: false,
                    error:
                        'Wystąpił błąd sieciowy. Prosimy upewnić się że urządzenie jest w trybie online i spróbować ponownie.',
                });
                setOtpModal(null);
                setOtp('');
            });
    };

    const [type, setType] = useState('');

    const [otpModal, setOtpModal] = useState(null);

    const [otp, setOtp] = useState('');

    return (
        <VerticallyCenteredSubLayout>
            <form
                onSubmit={handleSubmit(data => {
                    apiSMSCall(data);
                })}
            >
                <Box mt={3} mb={3}>
                    <Typography color="textPrimary" variant="h2" align="center">
                        Rejestracja w Foodinly
                    </Typography>
                </Box>
                <Box mt={3} mb={2}>
                    <Typography align="center" color="textSecondary" variant="body1">
                        Zarejestruj się, aby dodać swój lokal gastronomiczny do Foodinly
                    </Typography>
                    {!!state.error && !otpModal && (
                        <Box mt={3} mb={1}>
                            <Alert severity="error">{state.error}</Alert>
                        </Box>
                    )}
                    {!!state.message && !otpModal && (
                        <Box mt={3} mb={1}>
                            <Alert severity="success">{state.message}</Alert>
                        </Box>
                    )}
                </Box>
                <FormControl
                    fullWidth
                    variant="outlined"
                    style={{ marginTop: '12px', marginBottom: '20px' }}
                >
                    <InputLabel htmlFor="isChain">
                        Czy prowadzisz sieć lokali gastronomicznych?
                    </InputLabel>
                    <Select
                        inputProps={{
                            id: 'isChain',
                        }}
                        label="Czy prowadzisz sieć lokali gastronomicznych?"
                        fullWidth
                        onChange={e => {
                            setType(e.target.value);
                        }}
                    >
                        <MenuItem value="chain">
                            Tak, prowadzę sieć lokali gastronomicznych
                        </MenuItem>
                        <MenuItem value="single">Nie, prowadzę pojedynczy lokal</MenuItem>
                    </Select>
                </FormControl>
                <Collapse in={type === 'single'}>
                    <Box>
                        <Typography
                            color="textPrimary"
                            variant="h2"
                            align="center"
                            style={{ marginBottom: '10px' }}
                        >
                            Wprowadź dane konta
                        </Typography>
                        <TextField
                            fullWidth
                            label="Imię"
                            margin="normal"
                            name="firstName"
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                minLength: 3,
                                maxLength: 80,
                            })}
                            error={!!errors.firstName}
                        />
                        <TextField
                            fullWidth
                            label="Nazwisko"
                            margin="normal"
                            name="lastName"
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                minLength: 3,
                                maxLength: 80,
                            })}
                            error={!!errors.lastName}
                        />
                        <TextField
                            fullWidth
                            label="Numer telefonu"
                            margin="normal"
                            name="phone"
                            type="tel"
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                minLength: 9,
                                maxLength: 9,
                                pattern: /\d{9}$/,
                            })}
                            error={!!errors.phone}
                            helperText={
                                !!errors.phone
                                    ? 'Podaj numer telefonu bez spacji i myślników'
                                    : null
                            }
                        />
                        <FormControl
                            fullWidth
                            variant="outlined"
                            style={{ marginTop: '12px', marginBottom: '6px' }}
                            error={!!errors.restaurantType}
                        >
                            <InputLabel htmlFor="restaurantType">
                                Rodzaj lokalu gastronomicznego
                            </InputLabel>
                            <Controller
                                as={
                                    <Select
                                        inputProps={{
                                            id: 'restaurantType',
                                        }}
                                        label="Rodzaj lokalu gastronomicznego"
                                        fullWidth
                                    >
                                        <MenuItem value="restaurant">Restauracja</MenuItem>
                                        <MenuItem value="bar">Bar</MenuItem>
                                        <MenuItem value="hotel">Hotel</MenuItem>
                                    </Select>
                                }
                                name="restaurantType"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: true,
                                }}
                            ></Controller>
                            <FormHelperText>
                                {!!errors.restaurantType
                                    ? 'Wymagane jest podanie rodzaju lokalu'
                                    : null}
                            </FormHelperText>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="Adres e-mail"
                            margin="normal"
                            name="email"
                            type="email"
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                minLength: 3,
                                maxLength: 200,
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            error={!!errors.email}
                            helperText={
                                !!errors.email
                                    ? 'Wymagane jest podanie poprawnego adresu e-mail'
                                    : null
                            }
                        />
                        <TextField
                            fullWidth
                            label="Hasło"
                            margin="normal"
                            name="password"
                            type="password"
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                maxLength: 100,
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                            })}
                            error={!!errors.password}
                            helperText={
                                !!errors.password
                                    ? 'Hasło musi posiadać przynajmniej 8 znaków, oraz przynajmniej jedną małą literę, dużą literę oraz cyfrę.'
                                    : null
                            }
                        />{' '}
                        <Box alignItems="center" display="flex" ml={-1}>
                            <Controller
                                control={control}
                                name="policy"
                                defaultValue={false}
                                rules={{
                                    required: true,
                                    validate: val => val === true,
                                }}
                                render={({ onChange, onBlur, value, name }) => (
                                    <Checkbox
                                        color="primary"
                                        onBlur={onBlur}
                                        onChange={e => onChange(e.target.checked)}
                                        checked={value}
                                        name={name}
                                    />
                                )}
                            />
                            <Typography color="textSecondary" variant="body1">
                                Akceptuję{' '}
                                <Link
                                    color="primary"
                                    component={RouterLink}
                                    to="/regulamin"
                                    variant="h6"
                                >
                                    Regulamin Aplikacji{' '}
                                </Link>
                            </Typography>
                        </Box>
                        {errors?.policy && (
                            <Box mb={1}>
                                <Alert severity="error">
                                    Aby zarejestrować się w aplikacji, wymagana jest akceptacja
                                    regulaminu.
                                </Alert>
                            </Box>
                        )}
                        <Box my={2}>
                            <Button
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Zarejestruj się
                                {state.submitting && (
                                    <CircularProgress
                                        size={10}
                                        color="white"
                                    ></CircularProgress>
                                )}
                            </Button>
                        </Box>
                    </Box>
                </Collapse>
                <Collapse in={type === 'chain'}>
                    <Box mt={2} mb={3}>
                        <Typography color="textPrimary" variant="h2" align="center">
                            Porozmawiajmy
                        </Typography>
                    </Box>
                    <Box mb={1}>
                        <Typography align="left" color="textSecondary" variant="body1">
                            Skontaktuj się z nami, aby otrzymać spersonalizowaną ofertę
                            dostosowaną do potrzeb Twojej sieci lokali gastronomicznych.
                        </Typography>
                    </Box>
                    <Box mb={1}>
                        <Typography align="left" color="textSecondary" variant="body1">
                            E-mail:{' '}
                            <Link href="mailto:sprzedaz@foodinly.com">
                                sprzedaz@foodinly.com
                            </Link>
                        </Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography align="left" color="textSecondary" variant="body1">
                            Telefon: <Link href="tel:+48781555653">781-555-653</Link>
                        </Typography>
                    </Box>
                </Collapse>
                <Typography color="textSecondary" variant="body1">
                    Masz już konto w Foodinly?{' '}
                    <Link component={RouterLink} to="/" variant="h6">
                        Zaloguj się &#8594;
                    </Link>
                </Typography>
            </form>
            <Dialog
                open={!!otpModal}
                onClose={() => setOtpModal(null)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Weryfikacja SMS</DialogTitle>
                <DialogContent>
                    <Box mt={1} mb={3}>
                        <Typography variant="body1">
                            Na podany numer telefonu wysłaliśmy SMS z kodem aktywacyjnym.
                            Przepisz kod z SMS, aby kontynuować.
                        </Typography>
                    </Box>
                    <TextField
                        value={otp}
                        onChange={e => {
                            setOtp(e.target.value);
                        }}
                        label="Kod SMS"
                        variant="outlined"
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOtpModal(null)}>Anuluj</Button>
                    <Button
                        color="primary"
                        disabled={state.submitting || otp.length !== 6}
                        onClick={() => {
                            apiRegisterCall({ ...otpModal, code: otp });
                        }}
                    >
                        Wyślij
                        {state.submitting && (
                            <>
                                {' '}
                                <CircularProgress size={10} color="primary" />
                            </>
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </VerticallyCenteredSubLayout>
    );
};

export default RegisterView;

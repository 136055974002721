import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const kycLvlToStatus = (lvl, sent) => {
    if (lvl === 1) {
        return '1 - Trwa weryfikacja';
    }
    if (lvl === 2 && !sent) {
        return '2 - Wymagane dokumenty';
    }
    if (lvl === 2 && sent) {
        return '2 - Sprawdzamy dokumenty';
    }
    return '3 - Konto zweryfikowane';
};

const restaurantTypeToName = type => {
    if (type === 'bar') {
        return 'Bar';
    }
    if (type === 'hotel') {
        return 'Hotel';
    }
    if (type === 'restaurant') {
        return 'Restauracja';
    }
    if (type === 'chain') {
        return 'Indywidualny (sieć)';
    }
    return 'Nie ustalono';
};

export const AccountDataList = ({ data }) => (
    <List>
        <ListItem>
            <ListItemText
                primary={data.firstName + ' ' + data.lastName}
                secondary="Imię i Nazwisko"
            />
        </ListItem>
        <ListItem>
            <ListItemText primary={data.email} secondary="Adres e-mail" />
        </ListItem>
        <ListItem>
            <ListItemText primary={data.phone} secondary="Numer telefonu" />
        </ListItem>
        <ListItem>
            <ListItemText primary={data.restaurant.codeId} secondary="Identyfikator lokalu" />
        </ListItem>
        <ListItem>
            <ListItemText
                primary={restaurantTypeToName(data?.restaurant?.restaurantType)}
                secondary="Pakiet"
            />
        </ListItem>
        <ListItem>
            <ListItemText
                primary={kycLvlToStatus(data.kycStatus, !!data.kycDocumentSent)}
                secondary="Poziom weryfikacji"
            />
        </ListItem>
    </List>
);

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Typography } from '@material-ui/core';
import VerticallyCenteredSubLayout from '../layouts/VerticallyCenteredSubLayout';

const TermsAndConditionsView = () => {
    return (
        <VerticallyCenteredSubLayout>
            <Box mb={3}>
                <Typography color="textPrimary" variant="h2" align="center">
                    Regulamin Aplikacji Foodinly
                </Typography>
            </Box>{' '}
            <Typography color="textPrimary" variant="body2" align="justify">
                Aktualny regulamin aplikacji dostępny jest pod adresem: <br />
                <Link
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://foodinly.com/regulaminy/"
                    variant="h6"
                >
                    https://foodinly.com/regulaminy/
                </Link>
            </Typography>
            <Box mb={2} mt={2}>
                <Typography color="textSecondary" variant="body1">
                    <Link component={RouterLink} to="/" variant="h6">
                        Wróć do strony logowania
                    </Link>
                </Typography>
            </Box>
        </VerticallyCenteredSubLayout>
    );
};

export default TermsAndConditionsView;

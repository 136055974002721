import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    //useTheme,
    makeStyles,
    TablePagination,
    Tooltip,
    IconButton,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Snackbar,
} from '@material-ui/core';
import StoreFrontIcon from '@material-ui/icons/Storefront';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import dayjs from 'dayjs';
import AddIcon from '@material-ui/icons/Add';
import { Create, Delete, Done, ErrorOutline } from '@material-ui/icons';
import useAuthApiPost from '../hooks/useAuthApiPost';

const useStyles = makeStyles(theme => ({
    cardTitleIcon: {
        verticalAlign: 'bottom',
        padding: '0 8px',
    },
    downloadButton: {
        marginLeft: '12px',
    },
    cardButton: {
        margin: '10px 0 0 0 ',
        [theme.breakpoints.down('xs')]: {
            marginTop: '30px',
        },
    },
    cardHeader: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    actionCell: {
        [theme.breakpoints.up('md')]: { minWidth: '175px' },
        [theme.breakpoints.down('sm')]: { width: '88px' },
        [theme.breakpoints.down('xs')]: { width: '40px' },
    },
    snackbar: {
        background: 'white',
        color: theme.palette.text.primary,
        '& div': {
            textAlign: 'center',
            width: '100%',
        },
    },
    tooltipIcon: {
        verticalAlign: 'bottom',
        paddingRight: '10px',
    },
}));

const PremisesTable = ({ className, data, mutate, ...rest }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [editOpen, setEditOpen] = useState();
    const [deleteOpen, setDeleteOpen] = useState();
    const [editName, setEditName] = useState('');
    const [confirmName, setConfirmName] = useState('');
    const [newOpen, setNewOpen] = useState(false);
    const [newName, setNewName] = useState('');

    const [notification, setNotification] = useState(false);
    const [open, setOpen] = useState(false);

    const { loading: newLoading, callAPI: newCallAPI, success: newSuccess } = useAuthApiPost(
        'panel/restaurants'
    );

    const {
        loading: editLoading,
        callAPI: editCallAPI,
        success: editSuccess,
    } = useAuthApiPost('panel/restaurants', 'PATCH');

    const {
        loading: deleteLoading,
        callAPI: deleteCallAPI,
        success: deleteSuccess,
    } = useAuthApiPost('panel/restaurants', 'DELETE');

    useEffect(() => {
        if (newSuccess && !newLoading) {
            mutate({
                restaurants: [
                    ...data.restaurants,
                    {
                        _id: new Date().toString(),
                        name: newName,
                        created: new Date(),
                    },
                ],
                limit: data.limit,
            });
            setNewOpen(false);

            setOpen(true);
            setNotification({ type: 'SUCCESS', content: 'Lokal został utworzony' });
        }
        if (!newSuccess && !newLoading && !!newOpen) {
            setOpen(true);
            setNotification({ type: 'ERROR', content: 'Wystąpił błąd przy tworzeniu lokalu' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newSuccess, newLoading]);

    useEffect(() => {
        if (editSuccess && !editLoading) {
            mutate({
                restaurants: data.restaurants.map(r =>
                    r._id === editOpen?._id
                        ? {
                              ...r,
                              name: editName,
                          }
                        : r
                ),
                limit: data.limit,
            });
            setEditOpen(undefined);

            setOpen(true);
            setNotification({ type: 'SUCCESS', content: 'Nazwa lokalu została zmieniona' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editSuccess, editLoading]);

    useEffect(() => {
        if (deleteSuccess && !deleteLoading) {
            mutate({
                restaurants: data.restaurants.filter(r => r._id !== deleteOpen?._id),
                limit: data.limit,
            });
            setDeleteOpen(undefined);

            setOpen(true);
            setNotification({ type: 'SUCCESS', content: 'Lokal został usunięty' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteSuccess, deleteLoading]);

    return (
        <>
            <Card {...rest}>
                <CardHeader
                    className={classes.cardHeader}
                    title={
                        <>
                            <StoreFrontIcon
                                color="primary"
                                className={classes.cardTitleIcon}
                            />
                            Lokale gastronomiczne w mojej sieci{' '}
                            {data &&
                                data.limit <= data.restaurants.length &&
                                ' - osiągnięto limit'}
                        </>
                    }
                    action={
                        <Button
                            endIcon={<AddIcon />}
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.cardButton}
                            onClick={() => {
                                setNewOpen(true);
                                setNewName('');
                            }}
                            disabled={data && data.limit <= data.restaurants.length}
                        >
                            Dodaj lokal
                        </Button>
                    }
                />
                <CardContent>
                    <Box position="relative">
                        <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nazwa</TableCell>
                                        <TableCell align="right">Data utworzenia</TableCell>
                                        <TableCell align="right">Akcje</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.restaurants
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map(row => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.created
                                                        ? dayjs(row.created).format(
                                                              'DD-MM-YYYY'
                                                          )
                                                        : '-'}
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    className={classes.actionCell}
                                                >
                                                    <Tooltip title="Zmień nazwę">
                                                        <IconButton
                                                            className={classes.actionButton}
                                                            size="small"
                                                            aria-label="down"
                                                            onClick={() => {
                                                                setEditOpen(row);
                                                                setEditName(row.name);
                                                            }}
                                                        >
                                                            <Create />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Usuń lokal">
                                                        <IconButton
                                                            className={classes.actionButton}
                                                            size="small"
                                                            aria-label="down"
                                                            onClick={() => {
                                                                setDeleteOpen(row);
                                                            }}
                                                            disabled={!row.parent}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    {(!data.restaurants || data.restaurants.length === 0) && (
                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                <Typography variant="body2" align="center">
                                                    Twoje konto nie posiada jeszcze żadnych
                                                    restauracji.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[8, 25, 50]}
                                component="div"
                                count={data.restaurants.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={(event, newPage) => {
                                    setPage(newPage);
                                }}
                                onChangeRowsPerPage={event => {
                                    setRowsPerPage(parseInt(event.target.value, 10));
                                    setPage(0);
                                }}
                                labelRowsPerPage="Liczba wierszy na stronę"
                                labelDisplayedRows={({ from, to, count }) =>
                                    `${from}-${to} z ${count}`
                                }
                            />
                        </TableContainer>
                    </Box>
                </CardContent>
            </Card>
            <Dialog open={!!newOpen}>
                <DialogTitle id="alert-dialog-title">
                    Dodawanie lokalu gastronomicznego
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wprowadź nazwę nowego lokalu gastronomicznego:
                    </DialogContentText>
                    <TextField
                        fullWidth
                        label="Nazwa lokalu"
                        margin="normal"
                        name="nazwa"
                        variant="outlined"
                        disabled={newLoading}
                        onChange={e => {
                            setNewName(e.target.value);
                        }}
                        value={newName}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setNewOpen(false);
                        }}
                    >
                        Anuluj
                    </Button>
                    <Button
                        disabled={!newName || newName.length < 3 || newLoading}
                        onClick={() => {
                            newCallAPI({ name: newName });
                        }}
                        color="primary"
                        autoFocus
                    >
                        Utwórz lokal
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={!!editOpen}>
                <DialogTitle id="alert-dialog-title">Edytowanie lokalu</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wprowadź nową nazwę lokalu w polu tekstowym poniżej i zatwierdź zmianę
                        przyciskiem Zapisz.
                    </DialogContentText>
                    <TextField
                        fullWidth
                        label="Nazwa lokalu"
                        margin="normal"
                        name="nazwa"
                        variant="outlined"
                        disabled={editLoading}
                        onChange={e => {
                            setEditName(e.target.value);
                        }}
                        value={editName}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setEditOpen(undefined);
                        }}
                    >
                        Anuluj
                    </Button>
                    <Button
                        onClick={() => {
                            editCallAPI({ id: editOpen._id, name: editName });
                        }}
                        color="primary"
                        autoFocus
                        disabled={!editName || editName.length < 3 || editLoading}
                    >
                        Zapisz
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={!!deleteOpen}>
                <DialogTitle id="alert-dialog-title">Usuwanie lokalu</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Czy na pewno chcesz usunąć lokal: <strong>{deleteOpen?.name}</strong>?
                    </DialogContentText>
                    <DialogContentText>
                        Tej czynności nie można cofnąć. Aby potwierdzić chęć usunięcia lokalu,
                        przepisz jego nazwę poniżej i zatwierdź przyciskiem Usuń lokal.
                    </DialogContentText>
                    <TextField
                        fullWidth
                        label="Nazwa usuwanego lokalu"
                        margin="normal"
                        name="nazwa"
                        variant="outlined"
                        disabled={deleteLoading}
                        onChange={e => {
                            setConfirmName(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDeleteOpen(undefined);
                        }}
                    >
                        Anuluj
                    </Button>
                    <Button
                        color="primary"
                        autoFocus
                        disabled={confirmName !== deleteOpen?.name || deleteLoading}
                        onClick={() => {
                            deleteCallAPI({ id: deleteOpen?._id });
                        }}
                    >
                        Usuń lokal
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                ContentProps={{
                    classes: { root: classes.snackbar },
                }}
                open={open}
                autoHideDuration={3000}
                onClose={(event, reason) => {
                    setOpen(false);
                }}
                message={
                    notification.type === 'ERROR' ? (
                        <>
                            <ErrorOutline className={classes.tooltipIcon} color="primary" />{' '}
                            {notification.content}{' '}
                        </>
                    ) : (
                        <>
                            <Done className={classes.tooltipIcon} color="primary" />{' '}
                            {notification.content}{' '}
                        </>
                    )
                }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </>
    );
};

PremisesTable.propTypes = {
    className: PropTypes.string,
};

export default PremisesTable;

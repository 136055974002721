import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import { ArrowBack, ArrowDownward, ArrowUpward, Launch } from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(theme => ({
    cardTitleIcon: {
        verticalAlign: 'bottom',
        padding: '0 8px',
    },
    actionButton: {
        marginLeft: '12px',
        [theme.breakpoints.down('xs')]: { marginLeft: '6px' },
    },
    cardButton: {
        margin: '10px 0 0 10px ',
        [theme.breakpoints.down('xs')]: {
            marginTop: '20px',
        },
    },
    cardHeader: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    cardNoPaddingResponsive: {
        [theme.breakpoints.down('xs')]: {
            padding: '5px 0',
        },
    },
    countCell: {
        minWidth: '60px',
    },
    actionCell: {
        [theme.breakpoints.up('md')]: { minWidth: '175px' },
        [theme.breakpoints.down('sm')]: { width: '88px' },
        [theme.breakpoints.down('xs')]: { width: '40px' },
    },
    nameCell: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '120px',
            paddingRight: 0,
        },
    },
    pl: {
        fontWeight: 500,
    },
    eng: {
        fontStyle: 'italic',
    },
    cardFooterBtn: {
        marginLeft: '12px',
        marginBottom: '12px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '8px',
        },
    },
}));

export const validateName = s => !s.includes('|');

const CategoryEditor = ({ saving, touched, data, dispatchAction, onSave }) => {
    const classes = useStyles();
    const [deleted, setDeleted] = useState(null);
    const [edited, setEdited] = useState(null);
    const [adding, setAdding] = useState(false);
    const { handleSubmit, register, errors } = useForm();
    const {
        handleSubmit: handleEditSubmit,
        register: registerEdit,
        errors: errorsEdit,
        reset: resetEdit,
    } = useForm();
    const history = useHistory();

    return (
        <>
            <Card>
                <CardHeader
                    className={classes.cardHeader}
                    title={
                        <>
                            <RestaurantMenuIcon
                                color="primary"
                                className={classes.cardTitleIcon}
                            />
                            Zarządzanie kategoriami menu
                        </>
                    }
                    action={
                        <div>
                            <Button
                                endIcon={<SaveIcon />}
                                variant="contained"
                                size="small"
                                color="primary"
                                disabled={!touched || !!saving}
                                className={
                                    classes.cardButton + ' ' + classes.cardButtonResponsive
                                }
                                onClick={() => {
                                    onSave();
                                }}
                            >
                                Zapisz zmiany
                            </Button>
                            <Button
                                endIcon={<AddIcon />}
                                variant="contained"
                                size="small"
                                color="primary"
                                className={classes.cardButton}
                                onClick={() => {
                                    setAdding(true);
                                }}
                            >
                                Dodaj kategorię
                            </Button>
                        </div>
                    }
                />
                <CardContent className={classes.cardNoPaddingResponsive}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nazwa kategorii</TableCell>
                                    <TableCell align="right">Liczba dań</TableCell>
                                    <TableCell align="right">Akcje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, idx) => (
                                    <TableRow key={row._id}>
                                        <TableCell className={classes.nameCell} scope="row">
                                            <span className={classes.pl}>
                                                {row.category.split('|')[0]}
                                            </span>
                                            <br />
                                            <span className={classes.eng}>
                                                {row.category.split('|')[1]}
                                            </span>
                                        </TableCell>
                                        <TableCell className={classes.countCell} align="right">
                                            {row.meals.length}
                                            <Tooltip
                                                title={
                                                    row._id.startsWith('_')
                                                        ? 'Zapisz zmiany, aby zarządzać daniami'
                                                        : 'Zarządzaj daniami'
                                                }
                                            >
                                                <span>
                                                    <IconButton
                                                        component={Link}
                                                        disabled={row._id.startsWith('_')}
                                                        to={'/meals/' + row._id}
                                                        className={classes.actionButton}
                                                        size="small"
                                                        aria-label="up"
                                                    >
                                                        <Launch />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className={classes.actionCell}
                                        >
                                            <Tooltip
                                                enterNextDelay={500}
                                                title="Przenieś do góry"
                                            >
                                                <IconButton
                                                    disabled={idx === 0}
                                                    className={classes.actionButton}
                                                    size="small"
                                                    aria-label="up"
                                                    onClick={() => {
                                                        dispatchAction({
                                                            type: 'MOVE_UP',
                                                            payload: row._id,
                                                        });
                                                    }}
                                                >
                                                    <ArrowUpward />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                                enterNextDelay={500}
                                                title="Przenieś w dół"
                                            >
                                                <IconButton
                                                    disabled={idx === data.length - 1}
                                                    className={classes.actionButton}
                                                    size="small"
                                                    aria-label="down"
                                                    onClick={() => {
                                                        dispatchAction({
                                                            type: 'MOVE_DOWN',
                                                            payload: row._id,
                                                        });
                                                    }}
                                                >
                                                    <ArrowDownward />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edytuj kategorię">
                                                <IconButton
                                                    className={classes.actionButton}
                                                    size="small"
                                                    aria-label="down"
                                                    onClick={() => {
                                                        resetEdit({
                                                            namepl: row.category.split('|')[0],
                                                            nameen: row.category.split('|')[1],
                                                        });
                                                        setEdited(row._id);
                                                    }}
                                                >
                                                    <CreateIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Usuń kategorię">
                                                <IconButton
                                                    className={classes.actionButton}
                                                    size="small"
                                                    aria-label="down"
                                                    onClick={() => {
                                                        setDeleted(row._id);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {(!data || data.length === 0) && (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Typography variant="body2" align="center">
                                                Nie wprowadzono żadnych kategorii. Naciśnij
                                                przycisk "Dodaj Kategorię" aby utworzyć nową
                                                kategorię menu, do której będzie można dodawać
                                                dania.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <Button
                    onClick={() => {
                        history.goBack();
                    }}
                    variant="text"
                    startIcon={<ArrowBack />}
                    className={classes.cardFooterBtn}
                >
                    Wróć
                </Button>
            </Card>

            <Dialog open={!!deleted}>
                <DialogTitle id="alert-dialog-title">Usuwanie kategorii</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Czy na pewno chcesz usunąć kategorię:{' '}
                        {data?.find(el => el._id === deleted)?.category.split('|')[0]}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDeleted(null);
                        }}
                    >
                        Anuluj
                    </Button>
                    <Button
                        onClick={() => {
                            dispatchAction({ type: 'REMOVE', payload: deleted });
                            setDeleted(null);
                        }}
                        color="primary"
                        autoFocus
                    >
                        Usuń kategorię
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={!!adding}
                onClose={() => {
                    setAdding(null);
                }}
                aria-labelledby="form-dialog-title"
            >
                <form
                    onSubmit={handleSubmit(category => {
                        dispatchAction({
                            type: 'ADD',
                            payload: category.namepl + '|' + category.nameen,
                        });
                        setAdding(null);
                    })}
                >
                    <DialogTitle id="form-dialog-title">Dodawanie kateogrii menu</DialogTitle>{' '}
                    <DialogContent>
                        <DialogContentText>
                            Wprowadź nazwy (polskojęzyczną oraz anglojęzyczną) kategorii, którą
                            chcesz dodać do menu swojego lokalu. Po dodaniu kategorii, pamiętaj
                            o zapisaniu zmian.
                        </DialogContentText>

                        <TextField
                            autoFocus
                            id="namepl"
                            name="namepl"
                            label="Nazwa polskojęzyczna"
                            type="text"
                            fullWidth
                            variant="outlined"
                            error={!!errors?.namepl}
                            inputRef={register({
                                required: true,
                                validate: validateName,
                            })}
                        />
                        <Box height={16} />
                        <TextField
                            id="nameen"
                            name="nameen"
                            label="Nazwa anglojęzyczna"
                            type="text"
                            error={!!errors?.nameen}
                            fullWidth
                            variant="outlined"
                            inputRef={register({
                                required: true,
                                validate: validateName,
                            })}
                        />
                    </DialogContent>{' '}
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setAdding(null);
                            }}
                        >
                            Anuluj
                        </Button>
                        <Button type="submit" color="primary">
                            Dodaj kategorię
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog
                open={!!edited}
                onClose={() => {
                    setEdited(null);
                }}
                aria-labelledby="form-dialog-title"
            >
                <form
                    onSubmit={handleEditSubmit(category => {
                        dispatchAction({
                            type: 'EDIT',
                            payload: {
                                _id: edited,
                                category: category.namepl + '|' + category.nameen,
                            },
                        });
                        setEdited(null);
                    })}
                >
                    <DialogTitle id="form-dialog-title">Edycja kateogrii menu</DialogTitle>{' '}
                    <DialogContent>
                        <DialogContentText>
                            Wprowadź zaktualizowane nazwy (polskojęzyczną oraz anglojęzyczną)
                            kategorii.
                        </DialogContentText>

                        <TextField
                            autoFocus
                            id="namepl"
                            name="namepl"
                            label="Nazwa polskojęzyczna"
                            type="text"
                            fullWidth
                            variant="outlined"
                            error={!!errorsEdit?.namepl}
                            inputRef={registerEdit({
                                required: true,
                                validate: validateName,
                            })}
                        />
                        <Box height={16} />
                        <TextField
                            id="nameen"
                            name="nameen"
                            label="Nazwa anglojęzyczna"
                            type="text"
                            error={!!errorsEdit?.nameen}
                            fullWidth
                            variant="outlined"
                            inputRef={registerEdit({
                                required: true,
                                validate: validateName,
                            })}
                        />
                    </DialogContent>{' '}
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setEdited(null);
                            }}
                        >
                            Anuluj
                        </Button>
                        <Button type="submit" color="primary">
                            Zatwierdź
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default CategoryEditor;

import React from 'react';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import GiftIcon from '@material-ui/icons/Redeem';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Tooltip } from '@material-ui/core';
import dayjs from 'dayjs';

const iconSwitch = status => {
    switch (status) {
        case 'completed':
            return <DoneIcon />;
        case 'canceled':
            return <ClearIcon />;
        case 'new':
            return <AutorenewIcon />;
        case 'admin_gift':
            return <GiftIcon />;
        default:
            return '';
    }
};

const labelType = {
    new: 'W toku',
    canceled: 'Odrzucona',
    completed: 'Dokonana',
    admin_gift: 'Bonus',
};

const getTooltip = (status, date) => {
    const dateStr = dayjs(date).format('DD-MM-YYYY HH:mm');
    switch (status) {
        case 'new':
            return `Płatność rozpoczęta ${dateStr}`;
        case 'canceled':
            return `Płatność odrzucona ${dateStr}`;
        case 'completed':
            return `Płatność zaksięgowana ${dateStr}`;
        case 'admin_gift':
            return `Przedłużenie ważności usługi dokonane przez administratora`;
        default:
            return `Status płatności: ${status}`;
    }
};

export const PaymentStatusChip = ({ status, date }) => (
    <Tooltip title={getTooltip(status, date)}>
        <Chip
            icon={iconSwitch(status)}
            label={labelType[status]}
            color={status === 'canceled' ? 'primary' : 'default'}
        />
    </Tooltip>
);

import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserContext';

const useAuthApiGet = url => {
    const [state, setState] = useState({ loading: true, data: null, error: null });
    const { user } = useContext(UserContext);
    const { data, loading, error } = state;

    useEffect(() => {
        const fetchData = () => {
            setState(s => ({ ...s, loading: true, error: null }));

            fetch(process.env.REACT_APP_API_ADDR + url, {
                headers: !!user.token ? { Authorization: `Bearer ${user.token}` } : {},
            })
                .then(async res => {
                    const data = await res.json();
                    if (res.ok) {
                        setState(s => ({ ...s, loading: false, error: null, data: data }));
                    } else {
                        const msg =
                            res.status === 403
                                ? 'Wystąpił błąd uprawnień użytkownika. Prosimy spróbować ponownie. Jeżeli problem będzie się powtarzał, prosimy o ponowne zalogowanie do systemu.'
                                : data.error || 'Wystąpił błąd.';
                        setState(s => ({
                            ...s,
                            data: null,
                            loading: false,
                            error: msg,
                        }));
                    }
                })
                .catch(error => {
                    setState(s => ({
                        ...s,
                        loading: false,
                        error:
                            'Wystąpił błąd sieciowy. Prosimy od sprawdzenie czy urządzenie znajduje się w trybie online i ponowienie próby.',
                    }));
                });
        };

        if (url) {
            fetchData();
        }
    }, [url, user.token]);

    const setData = newData => {
        setState(s => ({ ...s, data: newData }));
    };

    return { data, loading, error, setData };
};

export default useAuthApiGet;

import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
    makeStyles,
    CardHeader,
    CardContent,
    Card,
    Stepper,
    StepLabel,
    Step,
    StepContent,
    Typography,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip,
    withStyles,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { UserContext } from '../UserContext';
import VerticallyCenteredSubLayout from '../layouts/VerticallyCenteredSubLayout';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CancelIcon from '@material-ui/icons/Cancel';
import dayjs from 'dayjs';
import { BillingDataList } from '../components/BillingDataList';
import { NewPaymentDetailsList } from '../components/NewPaymentDetailsList';
import DashboardError from '../components/DashboardError';
import { Help } from '@material-ui/icons';

export const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
    gridFix: {
        margin: 0,
        width: '100%',
    },
    cardTitleIcon: {
        verticalAlign: 'bottom',
        padding: '0 8px',
    },
    stepper: {
        [theme.breakpoints.down('xs')]: {
            padding: '8px 0',
        },
    },
    formControl: {
        width: '80%',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginLeft: 10,
    },
    calcelBtn: {
        marginRight: '10px',
    },
    progressContainer: {
        width: '100%',
        textAlign: 'center',
    },
    progress: {
        margin: '16px auto',
    },
}));

const monthOptions = [1, 2, 3, 6, 12];

const monthDescription = {
    '1': '1 miesiąc',
    '2': '2 miesiące',
    '3': '3 miesiące',
    '6': '6 miesięcy',
    '12': '12 miesięcy',
};

const NewPaymentView = () => {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(UserContext);
    const [state, setState] = useState({
        loading: true,
        error: null,
        data: null,
        currentStep: 0,
        months: 1,
        paymentError: null,
        paymentLoading: false,
    });
    const { loading, error, data, currentStep } = state;
    const finalize = () => {
        setState({ ...state, currentStep: 2, paymentLoading: true });
        const netPrice = state.months * data.price + data.activationPrice;
        fetch(process.env.REACT_APP_API_ADDR + 'panel/createorder', {
            method: 'POST',
            body: JSON.stringify({
                months: state.months,
                netAmount: netPrice,
                totalAmount:
                    data.price * state.months * ((100 + parseInt(data.vatClass)) / 100) +
                    data.activationPrice * ((100 + parseInt(data.vatClass)) / 100),
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`,
            },
        })
            .then(async response => {
                if (response.status !== 200) {
                    const parsedData = await response.json();
                    setState(state => ({
                        ...state,
                        paymentLoading: false,
                        paymentError: parsedData.error,
                    }));
                } else {
                    const parsedData = await response.json();
                    setState(state => ({
                        ...state,
                        paymentLoading: false,
                        paymentError: null,
                    }));
                    window.location.replace(parsedData.redirect);
                }
            })
            .catch(() => {
                setState(state => ({
                    ...state,
                    paymentLoading: false,
                    paymentError: 'Wystąpił błąd sieciowy. Prosimy spróbować ponownie.',
                }));
            });
    };
    useEffect(() => {
        fetch(process.env.REACT_APP_API_ADDR + 'panel/me', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`,
            },
        })
            .then(async response => {
                if (response.status !== 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        error: 'Wystąpił błąd przy ładowaniu danych profilu.',
                    }));
                } else {
                    const parsedData = await response.json();
                    const currentDate = dayjs();
                    const startDate = dayjs(parsedData.restaurant.validUntil);
                    const expired = currentDate.isAfter(startDate);
                    setState(state => ({
                        ...state,
                        loading: false,
                        data: {
                            ...parsedData,
                            date: expired ? currentDate : startDate,
                        },
                    }));
                }
            })
            .catch(() => {
                setState(state => ({
                    ...state,
                    loading: false,
                    error:
                        'Wystąpił błąd sieciowy. Prosimy upewnić się że urządzenie jest w trybie online i spróbować ponownie.',
                }));
            });
    }, [user.token]);

    if (!!loading)
        return (
            <VerticallyCenteredSubLayout width="900px">
                <Grid className={classes.gridFix} container spacing={3}>
                    <Grid item xs={12}>
                        <Skeleton height={500} />
                    </Grid>
                </Grid>
            </VerticallyCenteredSubLayout>
        );

    if (!!error) {
        return <DashboardError error={{ msg: error }} />;
    }

    return (
        <VerticallyCenteredSubLayout width="900px">
            <Dialog
                open={!data.billingNIP}
                handleClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Wprowadź ustawienia rozliczeniowe
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Twoje konto nie posiada zdefiniowanych ustawień rozliczeniowych, które
                        są nam potrzebne do wystawienia faktury za usługę. Przejdź do ekranu
                        ustawień i wprowadź dane swojej firmy.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button component={RouterLink} to="/settings" color="primary" autoFocus>
                        Ustawienia rozliczeniowe
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={data.kycStatus !== 3}
                handleClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Konto w trakcie weryfikacji</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Twoje konto jest w trakcie procesu weryfikacji. Możliwość składania
                        zamówień na usługi zostanie odblokowana po jego zakończeniu.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button component={RouterLink} to="/dashboard" color="primary" autoFocus>
                        Do pulpitu
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid className={classes.gridFix} container spacing={3}>
                <Grid item xl={12} lg={12} xs={12}>
                    <Card>
                        <CardHeader
                            title={
                                <>
                                    <CreditCardIcon
                                        color="primary"
                                        className={classes.cardTitleIcon}
                                    />
                                    Nowe zamówienie
                                </>
                            }
                        />
                        <CardContent>
                            <Stepper
                                className={classes.stepper}
                                activeStep={currentStep}
                                orientation="vertical"
                            >
                                <Step>
                                    <StepLabel>Specyfikacja zamówienia</StepLabel>
                                    <StepContent>
                                        <Grid
                                            className={classes.gridFix}
                                            container
                                            spacing={2}
                                        >
                                            <Grid item sm={6} xs={12}>
                                                <Typography variant="h4">
                                                    Przedłużenie usługi
                                                </Typography>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Liczba miesięcy
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={state.months}
                                                        onChange={event => {
                                                            setState({
                                                                ...state,
                                                                months: event.target.value,
                                                            });
                                                        }}
                                                    >
                                                        {monthOptions.map(el => (
                                                            <MenuItem value={el} key={el}>
                                                                {
                                                                    monthDescription[
                                                                        parseInt(el)
                                                                    ]
                                                                }
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <List>
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                (data.price / 100).toFixed(2) +
                                                                ' PLN'
                                                            }
                                                            secondary="Cena netto za miesiąc"
                                                        />
                                                    </ListItem>
                                                    {data.activationPrice > 0 && (
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={
                                                                    (
                                                                        data.activationPrice /
                                                                        100
                                                                    ).toFixed(2) + ' PLN'
                                                                }
                                                                secondary="Opłata aktywacyjna netto"
                                                            />
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    edge="end"
                                                                    aria-label="delete"
                                                                >
                                                                    <LightTooltip
                                                                        placement="top-end"
                                                                        arrow
                                                                        title="Jednorazowa opłata, którą doliczamy do Twojego pierwszego zamówienia."
                                                                    >
                                                                        <Help />
                                                                    </LightTooltip>
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </Grid>

                                            <Grid item sm={6} xs={12}>
                                                <Typography variant="h4" gutterBottom>
                                                    Kalkulacja parametrów usługi
                                                </Typography>
                                                <List>
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                (
                                                                    (data.price *
                                                                        state.months) /
                                                                    100
                                                                ).toFixed(2) + ' PLN'
                                                            }
                                                            secondary="Cena netto usługi"
                                                        />
                                                    </ListItem>
                                                    {data.activationPrice > 0 && (
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={
                                                                    (
                                                                        (data.activationPrice +
                                                                            data.price *
                                                                                state.months) /
                                                                        100
                                                                    ).toFixed(2) + ' PLN'
                                                                }
                                                                secondary="Łączna cena netto z opłatą aktywacyjną"
                                                            />
                                                        </ListItem>
                                                    )}
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={data.date
                                                                .add(state.months, 'month')
                                                                .format('DD-MM-YYYY')}
                                                            secondary="Nowy termin ważności usługi"
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="text"
                                                    onClick={() => {
                                                        history.goBack();
                                                    }}
                                                    className={classes.calcelBtn}
                                                >
                                                    Anuluj
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        setState({
                                                            ...state,
                                                            currentStep: 1,
                                                        });
                                                    }}
                                                >
                                                    Dalej
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepLabel>Podsumowanie</StepLabel>
                                    <StepContent>
                                        <Grid
                                            className={classes.gridFix}
                                            container
                                            spacing={2}
                                        >
                                            <Grid item sm={6} xs={12}>
                                                <Typography variant="h4">
                                                    Dane do faktury
                                                </Typography>
                                                <BillingDataList data={data} />
                                            </Grid>

                                            <Grid item sm={6} xs={12}>
                                                <Typography variant="h4" gutterBottom>
                                                    Podsumowanie zamówienia
                                                </Typography>
                                                <NewPaymentDetailsList
                                                    netPrice={
                                                        data.price * state.months +
                                                        data.activationPrice
                                                    }
                                                    totalPrice={
                                                        data.price *
                                                            state.months *
                                                            ((100 + parseInt(data.vatClass)) /
                                                                100) +
                                                        data.activationPrice *
                                                            ((100 + parseInt(data.vatClass)) /
                                                                100)
                                                    }
                                                    endDate={data.date
                                                        .add(state.months, 'month')
                                                        .format('DD-MM-YYYY')}
                                                    monthsLabel={
                                                        monthDescription[state.months]
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="text"
                                                    onClick={() => {
                                                        setState({
                                                            ...state,
                                                            currentStep: 0,
                                                        });
                                                    }}
                                                    className={classes.calcelBtn}
                                                >
                                                    Wróć
                                                </Button>
                                                <Button
                                                    variant="text"
                                                    component={RouterLink}
                                                    to="/settings"
                                                    className={classes.calcelBtn}
                                                >
                                                    Dane Konta
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={finalize}
                                                >
                                                    Potwierdź
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepLabel>Płatność</StepLabel>
                                    <StepContent>
                                        {state.paymentLoading && (
                                            <>
                                                <div className={classes.progressContainer}>
                                                    <CircularProgress
                                                        className={classes.progress}
                                                        size={36}
                                                    />
                                                </div>
                                                <Typography variant="body2" align="center">
                                                    Przetwarzamy Twoje zamówienie. Za chwilę
                                                    nastąpi przekierowanie do systemu
                                                    transakcyjnego PayU.
                                                </Typography>
                                            </>
                                        )}
                                        {state.paymentError && (
                                            <>
                                                <div className={classes.progressContainer}>
                                                    <CancelIcon
                                                        color="primary"
                                                        fontSize="large"
                                                    />
                                                </div>
                                                <Typography variant="body2" align="center">
                                                    {state.paymentError}
                                                </Typography>
                                            </>
                                        )}
                                    </StepContent>
                                </Step>
                            </Stepper>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </VerticallyCenteredSubLayout>
    );
};

export default NewPaymentView;

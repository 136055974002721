import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
    Box,
    Button,
    Link,
    TextField,
    Typography,
    CircularProgress,
    //makeStyles
} from '@material-ui/core';

import { Alert } from '@material-ui/lab';
import VerticallyCenteredSubLayout from '../layouts/VerticallyCenteredSubLayout';

const PasswordResetView = () => {
    const { register, handleSubmit } = useForm();

    const [state, setState] = useState({
        submitting: false,
        error: null,
        message: null,
    });

    const apiResetCall = async email => {
        setState({ ...state, submitting: true });
        fetch(process.env.REACT_APP_API_ADDR + 'auth/reset', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email }),
        })
            .then(async response => {
                if (response.status === 200) {
                    setState({
                        submitting: false,
                        message:
                            'Wiadomość e-mail z instrukcjami odzyskania hasła do konta została wysłana.',
                    });
                } else {
                    const data = await response.json();
                    setState({
                        ...state,
                        submitting: false,
                        error: data.error,
                        message: null,
                    });
                }
            })
            .catch(error => {
                setState({
                    ...state,
                    submitting: false,
                    error:
                        'Wystąpił błąd sieciowy. Prosimy upewnić się że urządzenie jest w trybie online i spróbowac ponownie.',
                    message: null,
                });
            });
    };

    return (
        <VerticallyCenteredSubLayout>
            <img
                style={{ width: '65%', margin: '0 auto', display: 'block' }}
                src="/foodinly_logo_crop.svg"
                alt="logo foodinly"
            ></img>
            <form
                onSubmit={handleSubmit(data => {
                    apiResetCall(data.email);
                })}
            >
                <Box mb={3}>
                    <Typography color="textPrimary" variant="h2" align="center">
                        Odzyskiwanie hasła do konta
                    </Typography>
                </Box>
                <Box mt={3} mb={1}>
                    <Typography
                        align="center"
                        color="textSecondary"
                        variant="body1"
                    >
                        Wprowadź adres e-mail podany przy rejestracji, aby
                        odzyskać dostęp do konta.
                    </Typography>
                    {state.error && (
                        <Box mt={3} mb={1}>
                            <Alert severity="error">{state.error}</Alert>
                        </Box>
                    )}
                    {state.message && (
                        <Box mt={3} mb={1}>
                            <Alert severity="success">{state.message}</Alert>
                        </Box>
                    )}
                </Box>
                <TextField
                    fullWidth
                    label="Adres e-mail"
                    margin="normal"
                    name="email"
                    type="email"
                    variant="outlined"
                    inputRef={register}
                    required={true}
                />
                <Box my={2}>
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        disabled={state.submitting}
                    >
                        Odzyskaj hasło{' '}
                        {state.submitting && (
                            <CircularProgress size={16}></CircularProgress>
                        )}
                    </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                    Nie chcesz zmieniać hasła?{' '}
                    <Link component={RouterLink} to="/" variant="h6">
                        Wróć do ekranu logowania
                    </Link>
                </Typography>
            </form>
        </VerticallyCenteredSubLayout>
    );
};

export default PasswordResetView;

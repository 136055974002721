import React from 'react';
import {
    Grid,
    makeStyles,
    CardHeader,
    CardContent,
    Card,
    Stepper,
    StepLabel,
    Step,
    StepContent,
    Typography,
    Button,
} from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import VerticallyCenteredSubLayout from '../layouts/VerticallyCenteredSubLayout';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
    gridFix: {
        margin: 0,
        width: '100%',
    },
    cardTitleIcon: {
        verticalAlign: 'bottom',
        padding: '0 8px',
    },
    stepper: {
        [theme.breakpoints.down('xs')]: {
            padding: '8px 0',
        },
    },
    calcelBtn: {
        marginRight: '10px',
    },
    progressContainer: {
        width: '100%',
        textAlign: 'center',
        margin: '16px 0',
    },
    progress: {
        margin: '16px auto',
    },
}));

const PaymentResultView = () => {
    const classes = useStyles();
    const location = useLocation();
    const failed = location.search.search('error=501') !== -1;
    return (
        <VerticallyCenteredSubLayout width="900px">
            <Grid className={classes.gridFix} container spacing={3}>
                <Grid item xl={12} lg={12} xs={12}>
                    <Card>
                        <CardHeader
                            title={
                                <>
                                    <CreditCardIcon
                                        color="primary"
                                        className={classes.cardTitleIcon}
                                    />
                                    Płatności PayU
                                </>
                            }
                        />
                        <CardContent>
                            <Stepper
                                className={classes.stepper}
                                activeStep={2}
                                orientation="vertical"
                            >
                                <Step>
                                    <StepLabel>Specyfikacja zamówienia</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Podsumowanie</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Płatność</StepLabel>
                                    <StepContent>
                                        {!failed && (
                                            <>
                                                <div className={classes.progressContainer}>
                                                    <DoneIcon
                                                        color="primary"
                                                        fontSize="large"
                                                    />
                                                </div>
                                                <div className={classes.progressContainer}>
                                                    <Typography variant="body2" align="center">
                                                        Dziękujemy za dokonanie płatności.
                                                    </Typography>
                                                </div>
                                                <div className={classes.progressContainer}>
                                                    <Button
                                                        variant="text"
                                                        component={RouterLink}
                                                        className={classes.calcelBtn}
                                                        to="/payments"
                                                    >
                                                        Zamówienia
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        component={RouterLink}
                                                        to="/dashboard"
                                                    >
                                                        Pulpit
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                        {!!failed && (
                                            <>
                                                <div className={classes.progressContainer}>
                                                    <CancelIcon
                                                        color="primary"
                                                        fontSize="large"
                                                    />
                                                </div>
                                                <div className={classes.progressContainer}>
                                                    <Typography variant="body2" align="center">
                                                        Płatność została odrzucona przez system
                                                        PayU. Prosimy o ponowne złożenie
                                                        zamówienia.
                                                    </Typography>
                                                </div>
                                                <div className={classes.progressContainer}>
                                                    <Button
                                                        variant="text"
                                                        component={RouterLink}
                                                        className={classes.calcelBtn}
                                                        to="/payments"
                                                    >
                                                        zamówienia
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        component={RouterLink}
                                                        to="/payments/new"
                                                    >
                                                        Nowe zamówienie
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </StepContent>
                                </Step>
                            </Stepper>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </VerticallyCenteredSubLayout>
    );
};

export default PaymentResultView;

import React, { useState } from 'react';
import VerticallyCenteredSubLayout from '../layouts/VerticallyCenteredSubLayout';
import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useSWR from 'swr';
import AccountDataCard from '../components/AccountDataCard';
import SettingsCard from '../components/SettingsCard';
import DashboardError from '../components/DashboardError';

const useStyles = makeStyles(() => ({
    gridFix: {
        margin: 0,
        width: '100%',
    },
}));

const SettingsView = () => {
    const classes = useStyles();
    const { data, error, isValidating, mutate } = useSWR('panel/me');
    const [state, setState] = useState(null);
    if (!state && !isValidating && !!data) {
        setState(data);
    }
    if (!data)
        return (
            <>
                {
                    <VerticallyCenteredSubLayout width="900px">
                        <Grid className={classes.gridFix} container spacing={3}>
                            <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
                                <Skeleton height={500} />
                            </Grid>
                            <Grid item lg={8} md={8} sm={12} xl={8} xs={12}>
                                <Skeleton height={500} />
                            </Grid>
                        </Grid>
                    </VerticallyCenteredSubLayout>
                }
            </>
        );
    if (error) {
        return <DashboardError error={error} />;
    }
    return (
        <VerticallyCenteredSubLayout width="900px">
            <Grid className={classes.gridFix} container spacing={3}>
                <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
                    <AccountDataCard data={data} />
                </Grid>
                <Grid item lg={8} md={8} sm={12} xl={8} xs={12}>
                    <SettingsCard
                        data={data}
                        onUpdate={newData => {
                            mutate({ ...data, ...newData }, true);
                        }}
                    />
                </Grid>
            </Grid>
        </VerticallyCenteredSubLayout>
    );
};

export default SettingsView;

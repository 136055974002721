import { Typography, makeStyles, Chip } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { getNamePL } from '../utils';
import { debounce } from 'lodash';
import SearchBox from './SearchBox';
import { AllergensContext } from '../views/AllergensContext';

const useStyles = makeStyles(theme => ({
    appBar: {
        background: theme.palette.background.gradient,
    },
    navbarTitle: {
        marginLeft: theme.spacing(2),
        flex: 1,
        [theme.breakpoints.down('xs')]: {
            fontSize: '18px',
        },
    },
    chips: {
        marginTop: 16,
        marginBottom: 12,
    },
    item: {
        margin: '4px 8px 4px 0',
    },
    inner: {
        paddingTop: 75,
    },
    searchWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
    groupTitle: {
        marginTop: theme.spacing(3),
    },
    hideSm: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    hideLg: {
        [theme.breakpoints.up('xs')]: {
            display: 'none',
        },
    },
    searchOuter: {
        [theme.breakpoints.up('sm')]: {
            position: 'sticky',
            top: 100,
        },
    },
    searchColumn: {
        [theme.breakpoints.up('sm')]: {
            overflow: 'visible',
        },
    },
}));

const SearchAllergens = ({ open, state, flipAllergen }) => {
    const { allAllergensFlattened, allAllergensFlattenedKeys } = useContext(AllergensContext);
    const classes = useStyles();
    const [filter, setFilter] = useState('');
    const [filtered, setFiltered] = useState([]);

    useEffect(() => {
        if (!!open) {
            //setState(allergens);
            setFilter('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const updateFilter = debounce(newFilter => {
        setFilter(newFilter);
        setFiltered(
            allAllergensFlattenedKeys.filter(allergenKey =>
                allAllergensFlattened[allergenKey]
                    .toLowerCase()
                    .includes(newFilter.toLowerCase())
            )
        );
    }, 500);

    const filteredAllergens = allAllergensFlattenedKeys.filter(allergenKey =>
        allAllergensFlattened[allergenKey].toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <>
            <div className={classes.searchWrapper}>
                <SearchBox
                    updateFilter={newValue => {
                        updateFilter(newValue);
                    }}
                />
            </div>
            {filter.length > 1 && (
                <>
                    {filtered.map(allergenKey => (
                        <Chip
                            onClick={() => {
                                flipAllergen(allergenKey);
                            }}
                            className={classes.item}
                            label={getNamePL(allAllergensFlattened[allergenKey])}
                            color={state.includes(allergenKey) ? 'primary' : 'default'}
                            key={allergenKey}
                        />
                    ))}
                    {filteredAllergens.length === 0 && (
                        <Typography variant="body2">Nie znaleziono alergenów.</Typography>
                    )}
                </>
            )}
        </>
    );
};

export default SearchAllergens;
